/*
 * NPM Libraries
 * (TODO) Add additional libraries
 * currently being loaded in dist/index.html
 * ie. Bootstrap, jQuery, jQuery-UI
 *
 */
// TODO-Split: Make sure that Video dial still works with jquery-ui

import $ from 'jquery';
global.$ = global.jQuery = $;

import 'jquery-ui/dist/jquery-ui';
// Bootstrap
import 'bootstrap-2.3.2/js/bootstrap-transition';
import 'bootstrap-2.3.2/js/bootstrap-alert';
import 'bootstrap-2.3.2/js/bootstrap-button';
import 'bootstrap-2.3.2/js/bootstrap-carousel';
import 'bootstrap-2.3.2/js/bootstrap-collapse';
import 'bootstrap-2.3.2/js/bootstrap-dropdown';
import 'bootstrap-2.3.2/js/bootstrap-modal';
import 'bootstrap-2.3.2/js/bootstrap-tooltip';
import 'bootstrap-2.3.2/js/bootstrap-popover';
import 'bootstrap-2.3.2/js/bootstrap-scrollspy';
import 'bootstrap-2.3.2/js/bootstrap-tab';
import 'bootstrap-2.3.2/js/bootstrap-typeahead';
import 'bootstrap-2.3.2/js/bootstrap-affix';
//
// // Plugins
import './lib/jquery-ui-touch-punch-improved/jquery.ui.touch-punch-improved';
import 'jquery.scrollto';
import 'jquery.textnodes/dist/jquery.textnodes';
import 'jquery.shyphen/src/jquery.shyphen';
import './lib/jquery-throttle-debounce/jquery.ba-throttle-debounce';
import 'jquery.uniform/jquery.uniform';
import './lib/jquery-filedrop/jquery.filedrop';
import 'jquery.validation/src/core';
import 'jquery.validation/src/delegate';
import 'jquery.validation/src/ajax';
import 'jquery.validation/src/additional/pattern';
import './lib/jquery.liveaddress/src/jquery.liveaddress';
import 'jquery-simulate/jquery.simulate';
import '@fortawesome/fontawesome-free/js/all';

import _ from 'lodash';

window._ = _;
