import '../less/survey.less';
import { report } from './lib/report';

// Setting up initial variables
window.ajax_timeout = process.env.AJAX_TIMEOUT;
window.ajax_error_max_tries = process.env.AJAX_ERROR_MAX_RETRIES;
window.collect_debug_page_timings = process.env.COLLECT_DEBUG_PAGE_TIMINGS;

// Set context_name based on URL path
window.context_name = /\/preview\//g.test(window.location.pathname)
  ? 'preview'
  : 'execution';
window.is_production = process.env.ENVIRONMENT === 'production';
window.appVersion = process.env.GIT_TAG_VERSION;
window.apiVersion = process.env.API_VERSION;

let loadingSize = 0;

const updateLoading = function () {
  loadingSize++;

  var loadingElem = document.getElementById('loadingText');
  if (!loadingElem) {
    clearInterval(window.loadingTimer);
    return;
  }

  if (loadingSize > 5) {
    loadingSize = 0;
    loadingElem.innerHTML = '';
  } else {
    loadingElem.innerHTML =
      loadingElem.innerHTML + '<span class="bull">&bull;</span>';
  }

  window.loadingTimer = setTimeout(updateLoading, 300);
};

updateLoading();

// Report javascript errors back to server.
window.onerror = function (msg, url, lno, colno, error) {
  try {
    // NOTE: 'Script error' generated from scripts violating
    // the browser's same-origin policy (ie. external).

    // NOTE 2: Unexpected token exception was being caused by
    // QSL containing references to unexisting JS scripts, which
    // yielded a 404 error when loading the script

    // TODO: Consider dropping this approach of `window.onerror` handling completely
    // as NewRelic does this out of box anyway

    const isSilent = (msg) => {
      if (/Script error/.test(msg)) return true;
      if (/Unexpected token/.test(msg)) {
        if (url.contains('404')) {
          return true;
        }
      }
    };

    const silent = isSilent(msg);

    var err_info = {
      message: msg,
      url: url,
      lineNumber: lno,
      columnNumber: colno,
      name: 'window.onerror',
      error: error,
    };

    report.logError(err_info, silent);
  } catch (err) {}
};

$.ajaxSetup({
  timeout: window.ajax_timeout || 30000,
});

$(document).ajaxError(function (event, request, settings) {
  if (typeof window.newrelic === 'undefined') return;

  var errorMessage = 'Failed network request (AJAX)';
  window.newrelic.noticeError(new Error(errorMessage), {
    errorMessage: errorMessage,
    response: request.statusText,
    status: request.status,
    requestURL: settings.url,
    method: settings.type,
    payload: settings.data,
    ajaxErrorListener: true,
    retries: settings.xhrFields && settings.xhrFields.retries,
  });
});

jQuery.fn.extend({
  g2code: function () {
    return parseInt(this.prop('id').split('-').slice(-1), 10);
  },
});

/*
 * GRYPHON
 */

window.Gryphon = {};

// The name of the event we'll use to initialize a widget
Gryphon.initialize_event = 'initialize';

// A function to initialize a widget.
Gryphon.load_widget = function (question, view) {
  if (question.error_message) {
    Gryphon.analytics.trackEvent(
      'respondent',
      'failed validation',
      question.error_message
    );
  }

  $.extend(question, { view: view });
  var widget = Gryphon.widgets.Widget.fromObject(question);

  if (NODE_ENV === 'development') {
    console.log('Loaded Widget', widget);
  }

  if (!widget) {
    throw new Error(
      'No widget class for type "' +
        question.type +
        '" and view "' +
        question.view +
        '"'
    );
  }
  Gryphon.widgets.instances.add(widget);
  widget.render();
  $(document).trigger('after_widget_rendered');
};

Gryphon.start_survey = function () {
  window.mainNav = MainNav('mainNav');
  segments = Gryphon.util.set_segments();
  Gryphon.util.get_runtime_vars();
  get_next_question();
  Gryphon.widgets.instances = new Gryphon.Collection();
};

// A function to initialize a template.
Gryphon.load_template = function (options) {
  var template = Gryphon.templates[options.template_name](options);

  if (NODE_ENV === 'development') {
    console.log('Loaded template', template, options);
  }

  if (!template) {
    throw new Error(
      'No template "' + options.template_name + '" present into the system"'
    );
  }

  this.$el = $('#' + options.element_id);

  if (!options.element_id || !this.$el.length) {
    throw new TypeError('No valid element_id specified!');
  }

  this.$el.html(template);
};

$(document).ready(function () {
  // Prevent right click on images and videos.
  if (window.context_name !== 'preview') {
    $('#main_cont').on('contextmenu', 'img, video', function (e) {
      e.preventDefault();
      return false;
    });
  }

  // Detect if the page load was in fact a refresh, and track it.
  var isRefresh = document.referrer.indexOf(document.location.origin) >= 0;
  if (isRefresh) {
    Gryphon.analytics.trackEvent('respondent', 'reload');
  }

  $(document).ajaxError(function (event, xhr, settings, exception) {
    var silent = false;

    // Intercept Status Code = 0, Status Text = 'error'
    // requests and silence Oops message on first attempt
    if (
      xhr &&
      xhr.statusText === 'error' &&
      xhr.status === 0 &&
      settings.xhrFields &&
      settings.xhrFields.retries > 0
    ) {
      silent = true;
    }

    try {
      var data = JSON.parse(xhr.responseText);
      if (data.error === 'reload') {
        // Don't ask the user if they want to reload.
        $(window).off('beforeunload');
        window.location.reload(true);
        return;
      }
    } catch (e) {}

    var err = {
      name: xhr.statusText || 'error',
      message: 'ajaxError: ' + (exception || 'error'),
      stack: settings.data || '',
      status: xhr.status,
    };

    // restrict the error reporting to our controlled domains
    // or data types we can scrape
    if (
      /.cloudfront.net/.test(settings.url) ||
      (/.yougov.com/.test(settings.url) && settings.dataType === 'html')
    ) {
      var response, err_msg;
      if (/.yougov.com/.test(settings.url)) {
        try {
          response = $(xhr.responseText)[3].children[1];
          err_msg = $(response).html().replace('<br>', ': ');
        } catch (e) {
          err_msg = xhr.statusText + ' (code: ' + xhr.status + ')';
        }
      } else {
        try {
          response = $(xhr.responseText);
          if (typeof response == 'object' && response.length >= 4) {
            err_msg = $.trim(response[3].nodeValue);
          } else {
            err_msg = xhr.responseText;
          }
        } catch (e) {
          err_msg = 'Unknown Cloudfront response error';
          if (e.message) {
            err_msg += ' (' + e.message + ')';
          }
        }
      }
      silent = true;
      err['url'] = settings.url;
      err['name'] = 'image.onerror';
      err['message'] =
        'Failed to load image resource (' +
        settings.url +
        ') with response: ' +
        err_msg;
    }
    if (/realitymine/.test(settings.url)) {
      silent = true;
    }

    report.logError(err, silent);
  });

  setTimeout(function () {
    var images = document.getElementsByTagName('img'),
      interval;
    interval = setInterval(function () {
      if (images && images.length > 0) {
        clearInterval(interval);
      }
      for (var i = 0; i < images.length; i++) {
        images[i].onerror = function (e) {
          // get the response from CF's custom error pages
          if (
            /.cloudfront.net/.test(e.target.currentSrc) ||
            /.yougov.com/.test(e.target.currentSrc)
          ) {
            $.ajax({
              url: e.target.currentSrc,
              dataType: 'html',
            });
          }
        };
      }
    }, 100);
  });

  // Initialize and start survey.
  $('.loading-no-js').removeClass('loading-no-js').addClass('loading');
  Gryphon.start_survey();
});
