/* globals global, debug_timing_push */

import { report } from './report';

// Navigation Click Event Handlers
global.click_next = function (event) {
  if ($('.dk-check').prop('checked')) {
    $('.image-highlight-input').val(null);
    $('.response-button-input, .card-sort-input').prop('checked', false);
  }
  global.loading_buttons('join_button');
  global.loading_buttons('next_button');
  debug_timing_push(0);
  if (
    ($(event).attr('halt') && event.halt) ||
    (event &&
      event.type === 'keypress' &&
      event.key !== ' ' &&
      event.key !== 'Enter')
  ) {
    return;
  }

  // Set button states to 'disabled' to
  // block further interaction
  mainNav.joinButton.disable();
  mainNav.backButton.disable();
  mainNav.nextButton.disable();
  mainNav.skipButton.disable();
  // Disable response buttons while request processing
  $('.response-button').css({ 'pointer-events': 'none' });

  Gryphon.analytics.trackEvent('respondent', 'next question');

  $('#notification').hide();

  var data = { __submit__: 1 };
  var all_str = '';
  var nm;

  $(page_state).trigger('before_submit', [event]);

  for (var x = 0; x < page_state.allvars.length; x++) {
    var v = page_state.allvars[x];
    var val = get_form_value(v);
    all_str += v + '|';

    if (val != null) {
      data[v] = val;
    }
  }

  for (nm in page_state.vardata) {
    data[nm] = page_state.vardata[nm];
  }

  if (page_state['starttime'] != null) {
    var endtime = Gryphon.util.get_page_time();
    data['__time__'] = Math.round(endtime - page_state['starttime']);
    data['__page_timing_source__'] = page_timing_source;
    if (collect_debug_page_timings) {
      data['__debug_timings__'] = debug_timings;
    }
    if (data['__time__'] < 0) {
      var message = '__time__: ' + data['__time__'];
      message += '; page_starttime: ' + page_state['starttime'];
      message += '; page_endtime: ' + endtime;
      message += '; source: ' + page_timing_source;
      report.logError({ name: 'Negative page timing', message: message }, true);
    }
  }

  data.__all__ = all_str;
  data.__this__ = page_state['this_page'];
  data.__spd_consent_text_shown__ = page_state['spd_consent_text_shown'];
  if (page_state['__facebook__']) {
    data.__facebook__ = JSON.stringify(page_state['__facebook__']);
  }
  get_next_question(data);
  return false;
};

global.click_back = function (event) {
  global.loading_buttons('back_button');
  debug_timing_push(1);
  if (
    ($(event).attr('halt') && event.halt) ||
    (event &&
      event.type === 'keypress' &&
      event.key !== ' ' &&
      event.key !== 'Enter')
  ) {
    return;
  }

  // Set button states to 'disabled' to
  // block further interaction
  mainNav.backButton.disable();
  mainNav.nextButton.disable();
  mainNav.skipButton.disable();
  // Disable response buttons while request processing
  $('.response-button').css({ 'pointer-events': 'none' });

  Gryphon.analytics.trackEvent('respondent', 'previous question');

  $('#notification').hide();
  var data = { __back__: 1 };
  get_next_question(data);
  return false;
};

global.click_skip = function (event) {
  debug_timing_push(3);
  if (
    ($(event).attr('halt') && event.halt) ||
    (event &&
      event.type === 'keypress' &&
      event.key !== ' ' &&
      event.key !== 'Enter')
  ) {
    return;
  }

  // Set button states to 'disabled' to
  // block further interaction
  mainNav.backButton.disable();
  mainNav.nextButton.disable();
  mainNav.skipButton.disable();
  // Disable response buttons while request processing
  $('.response-button').css({ 'pointer-events': 'none' });

  Gryphon.analytics.trackEvent('respondent', 'skip question');

  $('#notification').hide();
  var data = { __submit__: '__skip__' };
  var all_str = '';

  $(page_state).trigger('before_submit', [event]);

  for (var x = 0; x < page_state.allvars.length; x++) {
    var v = page_state.allvars[x];
    var val = get_form_value(v);
    all_str += v + '|';
  }

  if (page_state['starttime'] != null) {
    var endtime = Gryphon.util.get_page_time();
    data['__time__'] = Math.round(endtime - page_state['starttime']);
    data['__page_timing_source__'] = page_timing_source;
    data['__debug_timings__'] = debug_timings;
    if (data['__time__'] < 0) {
      var message = '__time__: ' + data['__time__'];
      message += '; page_starttime: ' + page_state['starttime'];
      message += '; page_endtime: ' + endtime;
      message += '; source: ' + page_timing_source;
      report.logError({ name: 'Negative page timing', message: message }, true);
    }
  }

  data.__all__ = all_str;
  data.__this__ = page_state['this_page'];
  data.__spd_consent_text_shown__ = page_state['spd_consent_text_shown'];

  get_next_question(data);
  return false;
};

global.loading_buttons = function (id) {
  $('#' + id).addClass('loading-button');
};

global.loaded_buttons = function () {
  $('button').removeClass('loading-button');
};
// End Navigation Click Event Handlers
