(function (widgets) {
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  function scale_setup(name, name_dk) {
    var check,
      h,
      h1,
      h2,
      v = $('input[name=' + name + ']');
    var selector = 'table.scale_table > tbody > tr.' + name;
    if (v.val() != '') {
      var init_val = parseInt(v.val(), 10);
      scale_select(name, name_dk, init_val);
    }
    check = setInterval(function () {
      if ($(window).width() >= 768) {
        h1 = $(selector + ' .scale-label-left').height();
        h2 = $(selector + ' .scale-label-right').height();
        h = h1 > h2 ? h1 + 4 : h2 + 4;
        if (h < 266) {
          clearInterval(check);
          $('table.scale_table').prop('cellpadding', 0);
        }
      } else {
        clearInterval(check);
        $('table.scale_table').prop('cellpadding', 10);
      }
    }, 250);
    $('#r-' + name_dk).click(function (e) {
      var scale_el = $('tr.' + name)
        .find('div.scale_cell_value')
        .filter('.scale_button_high');
      if (!$(".dk-check[name='" + name_dk + "']").prop('checked')) {
        $('input:hidden[name=' + name + ']').val(scale_el.attr('data-value'));
        scale_el.removeClass('hidden-response');
      } else {
        $('input:hidden[name=' + name + ']').val('');
        scale_el.addClass('hidden-response').end();
      }
      $(this).blur().trigger('changed');
    });
    handle_keyboard_checkbox('#r-' + name_dk);
  }

  var Scale = widgets.Widget.extend(
    {
      render: function render() {
        var that = this;
        Scale.__super__.render.call(this);
        this.$el.find('input:checkbox').uniform();
        $.each(this._object.category_options, function (i, category) {
          scale_setup(category.name, category.name_dk);
        });

        // Accessible element for previous answers
        this._object.category_options.forEach((option) => {
          if (option.answer) {
            $(`#${option.name}_note`).append(
              option.answer === 997
                ? `${this._object.dk_text}`
                : `${option.answer}`
            );

            // Ensure checkbox is checked
            // (can become unchecked even when DK response is true)
            if (option.answer === 997) {
              $(`#r-${option.name_dk}`).prop('checked', true).uniform();
            }
          }
        });

        var debounceRender = debounce(function () {
          that.render();
        }, 1000);
      },
    },
    {
      types: ['scale'],
      views: ['scale'],
    }
  );

  Scale.register();
  widgets.Scale = Scale;
})((Gryphon.widgets = Gryphon.widgets || {}));
