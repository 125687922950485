(function ($) {
  /* jshint
     evil: true,
     devel: true
   */
  /*
   * These few functions are for debugging in IE.
   */
  var dbug = 'yg-debug-box';

  var ygconsole = function () {
    var go_func = function (event) {
      var code = $('#yg-test-code').val();
      if (code.length) {
        var result = eval(code);
      }
    };
    var text = $(
      '<input type="text" id="yg-test-code" name="yg-test-code" />'
    ).css('width', '100%');
    var go = $('<input type="button" name="yg-test-go" value="go" />').click(
      go_func
    );
    $('#' + dbug)
      .append(text)
      .append(go);
  };

  $.ql = function () {
    if (window.console && console) {
      console.log(arguments);
    } else {
      if (!$('#' + dbug).length) {
        var dbug_box = $('<div id="' + dbug + '"></div>').css({
          position: 'absolute',
          height: '200px',
          width: '600px',
          overflow: 'scroll',
          bottom: 0,
          right: 0,
          border: '1px solid green',
          background: 'white',
        });
        $('body').append(dbug_box);
        ygconsole();
      }
      var msg = $('<div />');
      $.each(arguments, function () {
        msg.append(' ' + this + ' ');
      });
      $('#' + dbug)
        .find('input[name=yg-test-go]')
        .after(msg);
    }
  };
})(jQuery);
(function ($) {
  var clean_class = function (cls) {
    if (cls.substring(0, 1) == '.') {
      cls = cls.substring(1);
    }
    return cls;
  };

  var clean_unit = function (str) {
    return str.match(/\d+/)[0];
  };

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  var _plcslider = {
    defaults: {
      points: '.plcslider-point',
      pointer_html: '',
      values: [],
      stop: function () {},
    },

    containment: function (el) {
      return _plcslider._containment(el);
    },

    is_vertical: function () {
      return (
        (window.placement_narrow_view == 'vertical' &&
          $(window).width() <= 640) ||
        window.placement_wide_view == 'vertical'
      );
    },

    is_slider: function (el) {
      return $(el).hasClass('yg-plcslider');
    },

    values: function (el, values) {
      if (values && values.length > 0) {
        _plcslider._set_values(el, values);
        return el;
      }
      return _plcslider._get_values(el);
    },

    value: function (el, opts) {
      var self = $(el);
      var options = $(el).data('options');
      var kids = self.children();

      // we use the index to get/set the value
      var index = opts['index'] ? opts['index'] : false;

      if (!index && opts['point']) {
        index = _plcslider._get_point_index(el, opts['point']);
      }

      if (opts['value']) {
        _plcslider._set_value(el, index, opts['value']);
        return self;
      } else {
        return _plcslider._get_value(el, index);
      }
    },

    _get_point_index: function (el, point) {
      var self = $(el);
      var pid = $(point).attr('id');
      var index = false;
      $.each(self.children(), function (i, kid) {
        if ($(kid).attr('id') == pid) {
          index = i;
        }
      });
      return index;
    },

    _get_value: function (el, index) {
      // Return early if the slider element is not visible
      if (!el.is(':visible')) {
        return;
      }

      var self = $(el);
      var value = false;
      var kid = self.children()[index];
      var opts = self.data('options'),
        min = parseFloat(opts['options']['value_min']),
        max = parseFloat(opts['options']['value_max']),
        precision = parseInt(opts['options']['decimal_precision']);
      if (kid) {
        var c = $(el).plcslider('containment');
        if (_plcslider.is_vertical()) {
          var center = Math.round($(kid).height() / 2);
          if (c.offset(kid) < 0) {
            value = min;
            localStorage.setItem($(kid).attr('id'), 0);
          } else if (
            c.offset(kid) >= $('.placement-slider:visible').height() - center ||
            (c.offset(kid) >= $('.placement-slider:visible').height() - 10 &&
              center == 8)
          ) {
            value = max;
            localStorage.setItem(
              $(kid).attr('id'),
              $('.placement-slider:visible').height() - center
            );
            $(kid).css('top', $('.placement-slider:visible').height() - center);
          } else {
            value = parseFloat(
              ((c.offset(kid) + center) / c.height()) * (max - min) + min
            );
            localStorage.setItem($(kid).attr('id'), c.offset(kid)); // used when getting the value (ie. 'back' click)
          }
          // In case the displayed value is not updated
          if (opts.options.show_value) {
            var value_em = $(kid).find('.placement-pointer-text em');
            if (value_em.text() !== Gryphon.util.round(value, precision)) {
              value_em.html(Gryphon.util.round(value, precision));
            }
          }
        } else {
          value = parseFloat(
            ((c.offset(kid) + 1) / c.width()) * (max - min) + min
          );
          localStorage.setItem($(kid).attr('id'), c.offset(kid)); // used when getting the value (ie. 'back' click)
        }
      }
      if (value > max) {
        value = max;
      }
      if (value <= min) {
        value = min;
      }
      // If our range contains decimals we should
      // round our returned value accordingly
      return Gryphon.util.round(value, precision);
    },

    _get_values: function (el) {
      var self = $(el);
      var values = [];
      $.each(self.children(), function (k, v) {
        values.push(_plcslider._get_value(el, k));
      });
      return values;
    },

    _get_offset_from_value: function (args, value) {
      const { range, pixels } = args;

      return parseFloat(
        Gryphon.util.round(
          pixels / ((range.max - range.min + range.min) / parseInt(value)),
          2 // precision
        )
      );
    },

    _set_value: function (el, index, value) {
      var self = $(el);
      var opts = self.data('options'),
        min = parseFloat(opts['options']['value_min']),
        max = parseFloat(opts['options']['value_max']);
      $(self.children()).each(function (k, pointer) {
        if (k == index) {
          self.append(pointer);
          var t,
            l,
            c = $(el).plcslider('containment'); // no scrubs

          var offsetFromValue = _plcslider._get_offset_from_value(
              { range: { min, max }, pixels: c.width() },
              value
            ), // default (horizontal)
            offsetFromLocalStorage = parseFloat(
              localStorage.getItem($(pointer).attr('id'))
            );

          if (_plcslider.is_vertical()) {
            offsetFromValue = _plcslider._get_offset_from_value(
              { range: { min, max }, pixels: c.height() },
              value
            ); // overwrite default (vertical)
            t = c.height();
            if (value < max) {
              t =
                offsetFromLocalStorage !== offsetFromValue
                  ? offsetFromValue
                  : offsetFromLocalStorage;
            }
            if (c.reversed()) {
              $(pointer).css({ bottom: t - 18, left: -30 });
            } else {
              $(pointer).css({ top: t, left: -30 });
            }
          } else {
            l = c.width();
            if (value < max) {
              l =
                offsetFromLocalStorage !== offsetFromValue
                  ? offsetFromValue
                  : offsetFromLocalStorage;
            }
            if (c.reversed()) {
              $(pointer).css({ left: c.width() - l - 2 });
            } else {
              $(pointer).css({ left: l });
            }
          }
          if (opts.options.show_value) {
            setTimeout(function () {
              var pointerTxt = $(pointer)
                .find('.placement-pointer-text')
                .text();
              var displayValue = value
                .toString()
                .replace('.', opts.options.decimal_separator);
              if ($(pointer).find('.placement-pointer-text em').length > 0) {
                // update value
                $(pointer)
                  .find('.placement-pointer-text em')
                  .html(displayValue);
              } else {
                // add value
                $(pointer)
                  .find('.placement-pointer-text')
                  .html(pointerTxt + '<em>' + displayValue + '</em>');
              }
            });
          }
        }
      });
    },

    _set_values: function (el, values) {
      var self = $(el);
      var kids = self.children();
      var options = $(el).data('options');
      var buffer = options['buffer_left'] ? options['buffer_left'] : 0;

      // default to 0
      $.each(kids, function (k, v) {
        if (!values[k]) {
          values[k] = 0;
        }
      });

      $.each(values, function (k, v) {
        _plcslider._set_value(el, k, v);
      });
    },

    _add_point: function (el, drag) {
      var args = drag;
      if (drag['drag']) {
        drag = args['drag'];
      }

      $('#back_button, #next_button').on('click', function () {
        window.storeDropValues = null;
      });

      if (drag.length > 0) {
        if (
          window &&
          window.storeDropValues &&
          window.storeDropValues.length > 0
        ) {
          window.storeDropValues.push(drag[0]);
        } else {
          window.storeDropValues = drag;
        }
      } else {
        drag = window.storeDropValues;
      }

      var self = $(el);
      var options = $(el).data('options');
      var c = _plcslider._containment(self);
      var alreadyPlaced = $(drag).hasClass('placement-indicator');
      var isInDropZone;

      // grab the original left value for use later if it was
      // from a drop

      var drag_oleft = null;
      if (drag && drag.position() && drag.position().left) {
        drag_oleft = drag.position().left;
      }

      var drag_otop = !_plcslider.is_vertical() ? 40 : 0;
      if (_plcslider.is_vertical()) {
        if (drag && drag.position() && drag.position().top && c && c.north) {
          drag_otop = !alreadyPlaced
            ? drag.position().top - c.north
            : drag.position().top;
          if (drag_otop < 0) {
            if ($(drag).css('margin-top') === '-10px') {
              drag_otop = 0;
            } else {
              drag_otop = -8;
            }
          } else if (
            drag_otop > c.height() - 10 &&
            $(drag).css('margin-top') !== '-10px'
          ) {
            drag_otop = c.height() - 10;
          } else if (
            drag_otop > c.height() &&
            $(drag).css('margin-top') === '-10px'
          ) {
            drag_otop = c.height();
          }
        }
      } else if (!_plcslider.is_vertical() && $(drag) && $(drag).css('top')) {
        if (
          $(drag).css('top') == '0px' ||
          parseInt($(drag).css('top').replace('px', '')) !== 40
        ) {
          $(drag).css('top', 40);
        }
      }

      var checkIfInDropZone = function (ui) {
        isInDropZone =
          ui.offset.top > window.slider.offset.top &&
          ui.offset.top < window.slider.offset.bottom;
      };

      var drag_opts = {
        top: drag_otop,
        revert: function (drop) {
          if (_plcslider.is_vertical()) {
            return !isInDropZone;
          }
          return !drop;
        },
        scroll: false,
        drag: function (event, ui) {
          if (window.slider.el) {
            _plcslider.calc_dimensions(window.slider.el);
          }
          // horizontal layout
          if (!_plcslider.is_vertical()) {
            const widthOfRuler = $('.placement-drop:visible').width();
            if (ui.position.left < 0) {
              ui.position.left = 0;
              ui.offset.left = 0;
              ui.originalPosition.left = 0;
            } else if (ui.position.left > widthOfRuler) {
              ui.position.left = widthOfRuler;
              ui.offset.left = widthOfRuler;
              ui.originalPosition.left = widthOfRuler;
            }
            var snapBot =
              Math.abs(
                window.slider.offset.bottom -
                  (ui.offset.top - 40 + window.slider.height)
              ) <= 10;

            if (snapBot) {
              ui.position.top = 40;
              return;
            }
          }
          // vertical layout
          if (_plcslider.is_vertical()) {
            const heightOfRuler = $('.placement-drop:visible').height();
            if (ui.position.top < 0) {
              ui.position.top = 0;
              ui.offset.top = 0;
              ui.originalPosition.top = 0;
            } else if (ui.position.top > heightOfRuler) {
              ui.position.top = heightOfRuler;
              ui.offset.top = heightOfRuler;
              ui.originalPosition.top = heightOfRuler;
            } else {
              ui.offset.top = ui.position.top;
              ui.originalPosition.top = ui.position.top;
            }
            checkIfInDropZone(ui);
            var snapLeft =
              Math.abs(
                window.slider.offset.left -
                  (ui.offset.left + window.slider.width + 11)
              ) <= 10;

            if (snapLeft) {
              ui.position.left = -27;
              return;
            }
          }
        },
        stop: function (event, ui) {
          if (options['stop']) {
            var value_opts = { point: ui.helper };
            ui.value = $(el).plcslider('value', value_opts);
            ui.draggable = ui.helper;
            options['stop'](event, ui);
          }
          $($(ui.helper).parent()).append(ui.helper);
        },
      };

      // merge select options from the create dict/hash
      // the functionality is the same as ui.draggable
      $.each(['handle', 'zIndex'], function (k, v) {
        if (options[v]) {
          drag_opts[v] = options[v];
        }
        if (options['top'] !== drag_opts['top']) {
          options['top'] = drag_opts['top'];
        }
      });

      if ($(drag).draggable('instance')) $(drag).draggable('destroy');
      $(drag)
        .removeClass(clean_class(options['points']))
        .addClass(clean_class(options['pointers']))
        .css('position', 'absolute')
        .draggable(drag_opts);

      // append the pointer so it is on top
      $(self).append(drag);

      if (!_plcslider.is_vertical()) {
        // if the drop happened outside the container, then move
        // it to the respective edge
        setTimeout(function () {
          // initial drops from options / DK box onto placement
          if (
            !alreadyPlaced &&
            drag_oleft &&
            c &&
            c.east &&
            c.west &&
            drag &&
            drag.position()
          ) {
            if (
              (drag_oleft < c.west && drag_oleft <= -25) ||
              drag.position().left <= -6
            ) {
              $(drag).css('left', -6);
            } else if (drag.position().left > c.width() - $(drag).width() / 2) {
              $(drag).css('left', c.width() - $(drag).width() / 2);
            } else if (drag_oleft > c.east) {
              // set it to the width + 1 to make sure the value is 100
              $(drag).css('left', c.width() + 1);
            } else if (
              drag_oleft > c.east - (c.width() - 700) ||
              drag.position().left > 700
            ) {
              $(drag).css('left', c.width() - (c.width() - 700));
            }
            if ($(drag).css('z-index') == 'auto') $(drag).css('z-index', 1000);
          }
        });
      } else if (_plcslider.is_vertical()) {
        setTimeout(function () {
          if (
            drag_otop === 40 &&
            drag.position().left === -30 &&
            !alreadyPlaced
          ) {
            $(drag).css({ left: -27, top: drag.position().top - 8 });
          } else if (drag_otop === 40 && drag.position().left === -30) {
            $(drag).css({ left: -27, top: drag.position().top - 8 });
          }
        });
      }

      return el;
    },

    _containment: function (el, buffer) {
      /* jshint laxbreak: true */
      var self = $(el);
      var left = self.offset().left;
      var top = self.offset().top;

      var fmt = function (v) {
        return Number(clean_unit(v));
      };

      var border = {
        margin: {
          left: fmt(self.css('margin-left')),
          right: fmt(self.css('margin-right')),
          top: fmt(self.css('margin-top')),
          bottom: fmt(self.css('margin-bottom')),
        },
        padding: {
          left: fmt(self.css('padding-left')),
          right: fmt(self.css('padding-right')),
          top: fmt(self.css('padding-top')),
          bottom: fmt(self.css('padding-bottom')),
        },
      };
      var c = {
        border: border,
        north: Math.round(top),
        south: Math.round(top + self.height()),

        west: Math.round(left + border.margin.left - border.padding.left),

        east: Math.round(
          left +
            self.width() +
            border.margin.left -
            border.padding.left -
            border.margin.right
        ),

        width: function () {
          return this.east - this.west - this.border.padding.left;
        },

        height: function () {
          return this.south - this.north;
        },

        reversed: function () {
          try {
            var isReversed =
              window.slider.opts.left_message !==
              window.slider.opts.left_message_ordered;
            return isReversed;
          } catch (e) {
            return false;
          }
        },

        offset: function (p) {
          // offset from the doc -
          // offset of the slider + padding-left (horizontal)
          if (_plcslider.is_vertical()) {
            return this.reversed()
              ? this.south - $(p).offset().top
              : $(p).offset().top - this.north;
          } else {
            return this.reversed()
              ? this.east + this.border.padding.right - $(p).offset().left
              : $(p).offset().left - (this.west + this.border.padding.left);
          }
        },

        left: function (p) {
          // the reverse of the offset
          return this.west - this.border.padding.left;
        },
      };
      window._containment = c;
      return c;
    },

    add: function (slider, opts) {
      var options = $(slider).data('options');
      var point = opts['point'];
      if (!point || !opts['value']) {
        return slider;
      }
      $(slider).plcslider('_add_point', point);

      $(slider).plcslider('value', {
        index: $(slider).children().length - 1,
        value: opts['value'],
      });

      var ui = {
        draggable: $(point),
        offset: point.offset(),
      };
      var event = {};
      if (options['drop'] && $.isFunction(options['drop'])) {
        options['drop'](event, ui);
      }
      return slider;
    },

    calc_dimensions: function (slider) {
      if (slider && $(slider).is(':visible')) {
        window.slider = {
          el: slider,
          height: $(slider).height(),
          width: $(slider).width(),
          offset: {
            top: $(slider).offset().top,
            bottom: $(slider).offset().top + $(slider).height(),
            left: $(slider).offset().left,
          },
        };

        !window._containment && _plcslider._containment(slider);
        return window.slider;
      }
    },

    create: function (slider, opts) {
      // jQuery UI Draggable fix while dragging / scrolling at the
      // same time, since we're using relative positioning
      $('body').css('overflow', 'auto');
      var options = $.extend(_plcslider.defaults, opts);
      _plcslider.calc_dimensions(slider);
      var slideHeight = window.slider.height,
        sliderBot = window.slider.offset.bottom;
      if (options) {
        $(slider).data('options', options);
        window.slider.opts = options.options;
      }
      $(opts['points']).each(function () {
        $(this).draggable({
          revert: 'invalid',
          zIndex: 3, // dragging placement
          scroll: false,
          stop: function (event, ui) {
            setTimeout(function () {
              if ($(ui.helper).hasClass('placement-item')) {
                // reset left/top if reverting
                $(ui.helper).attr('style', 'position: relative; z-index: 3;');
              }
            });
          },
          drag: function (event, ui) {
            // horizontal layout
            if (!_plcslider.is_vertical()) {
              var snapBot =
                Math.abs(sliderBot - (ui.offset.top + slideHeight)) <= 10;

              if (snapBot) {
                ui.position.top =
                  $(ui.helper).parent().attr('id') == 'placement-notsure'
                    ? -94
                    : window.slider.offset.top -
                      $('.placement-options').offset().top -
                      slideHeight / 2;
                return;
              }
            }
            // vertical layout
            if (_plcslider.is_vertical()) {
              var snapLeft =
                Math.abs(window.slider.offset.left - ui.offset.left) <= 10;

              if (snapLeft) {
                if ($(window).width() <= 640) {
                  ui.position.left = window.slider.offset.left;
                }
                return;
              }
            }
          },
        });
      });
      $(slider)
        .children()
        .each(function (k, v) {
          $(slider).plcslider('_add_point', $(v));
        });

      if (!options['values']) {
        options['values'] = [];
      }
      $(slider).plcslider('values', options['values']);
      $(slider).droppable({
        tolerance: 'touch',
        accept: function (draggable) {
          return draggable.hasClass('placement-item') ||
            draggable.hasClass('placement-indicator')
            ? true
            : false;
        },
        classes: {
          'ui-droppable-hover': 'hover-drop',
        },
        drop: function (event, ui) {
          var drag = ui.draggable;
          var offset = ui.draggable.offset();
          var innerWidth = drag
            .find('.placement-pointer-text-container')
            .width();
          var innerHeight = drag
            .find('.placement-pointer-text-container')
            .height();
          var alreadyPlaced = drag.hasClass('placement-indicator');
          $(slider).plcslider('_add_point', {
            drag: drag,
            from_drop: true,
          });
          // Horizontal :: Positions based on the middle of the element width
          // Vertical   :: Positions based on the middle of the element height
          var topPosH, topPosV, leftPosH;
          topPosH = options['top'] ? options['top'] : 0;
          topPosV = alreadyPlaced
            ? options['top']
            : options['top'] + innerHeight / 2;
          leftPosH = alreadyPlaced
            ? offset.left - $(slider).offset().left
            : offset.left + innerWidth / 2 - $(slider).offset().left;
          $(drag).css({
            top: _plcslider.is_vertical() ? topPosV : topPosH,
            left: _plcslider.is_vertical() ? -7 : leftPosH,
          });
          if (opts['drop'] && $.isFunction(opts['drop'])) {
            opts['drop'](event, ui);
          }
          if (opts['stop'] && $.isFunction(opts['stop'])) {
            opts['stop'](event, ui);
          }
        },
      });
      $(slider).addClass('yg-plcslider');

      // handle window resizes
      var $onResize = debounce(function () {
        _plcslider.calc_dimensions(slider);
      }, 250);
      window.removeEventListener('resize', $onResize);
      window.addEventListener('resize', $onResize);

      return slider;
    },
  };

  $.fn.plcslider = function (action, options) {
    var value = false;
    var result = this.each(function () {
      var self = $(this);
      if (_plcslider[action]) {
        value = _plcslider[action](self, options);
      } else {
        if (!action) {
          options = {};
        } else {
          options = action;
        }
        action = 'create';
        value = _plcslider[action](self, options);
      }
    });
    return value !== false ? value : result;
  };
})(jQuery);
