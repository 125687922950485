/* jshint
  curly: false
*/

// requires interface with iutil and jquery.dimensions

jQuery.iThermometer = {
  registry: {},

  build: function (o) {
    var id = this.prop('id');
    jQuery.iThermometer.registry[id] = {};
    var down = false;
    var callback = o.onSlide;
    var done_cb = o.onRelease;
    var direction = o.direction != null ? o.direction : 'vertical';
    var range = o.range != null ? o.range : new Array(0, 100);
    jQuery.iThermometer.registry[id].therm_cb = callback;
    jQuery.iThermometer.registry[id].therm_dir = direction;
    jQuery.iThermometer.registry[id].therm_range = range;
    this.mousedown(function (e) {
      down = true;
      jQuery.iThermometer.process_callback(callback, e, direction, range, this);
    });
    this.mouseup(function (e) {
      down = false;
      jQuery.iThermometer.process_callback(callback, e, direction, range, this);
      if (done_cb) done_cb();
    });
    this.mousemove(function (e) {
      if (down)
        jQuery.iThermometer.process_callback(
          callback,
          e,
          direction,
          range,
          this
        );
    });
  },

  process_callback: function (callback, e, direction, range, item) {
    if (callback != null) {
      var pointer = { x: e.pageX, y: e.pageY };
      var position = $(item).position();
      var size = {
        width: $(item).width(),
        height: $(item).height(),
      };
      var coord, value, ratio;

      if (direction == 'horizontal') {
        coord = pointer.x - position.left;
        if (coord < 0) coord = 0;
        else if (coord >= size.width) coord = size.width - 1;
        ratio = coord / (size.width - 1);
      } else {
        coord = pointer.y - position.top;
        if (coord < 0) coord = 0;
        else if (coord >= size.height) coord = size.height - 1;
        ratio = coord / (size.height - 1);
      }
      value = Math.round(ratio * (range[1] - range[0]) + range[0]);
      if (range[1] > range[0]) {
        if (value > range[1]) value = range[1];
        else if (value < range[0]) value = range[0];
      } else {
        if (value > range[0]) value = range[0];
        else if (value < range[1]) value = range[1];
      }
      callback(value, coord);
    }
  },

  callback_from_value: function (value) {
    var id = this.prop('id');
    var callback = jQuery.iThermometer.registry[id].therm_cb;
    var direction = jQuery.iThermometer.registry[id].therm_dir;
    var range = jQuery.iThermometer.registry[id].therm_range;
    var ratio = (value - range[0]) / (range[1] - range[0]);
    var coord;
    var dimension;
    if (callback != null) {
      dimension = direction === 'horizontal' ? 'width' : 'height';
      coord = parseInt(this.css(dimension), 10) * ratio;
    }
    callback(value, coord);
  },

  straight_callback: function (value, coord) {
    var id = this.prop('id');
    var callback = jQuery.iThermometer.registry[id].therm_cb;
    callback(value, coord);
  },
};

jQuery.fn.extend({
  Thermometer: jQuery.iThermometer.build,
  SetThermometer: jQuery.iThermometer.callback_from_value,
  CallbackThermometer: jQuery.iThermometer.straight_callback,
});
