import CardSort from './mixins/cards';
import MatrixFlashcards from './mixins/matrix-flashcards';
import InputBox from './mixins/input_box';

(function (widgets, BaseClass) {
  var Matrix = widgets.Widget.extend(
    {
      constructor: function MatrixConstructor(object) {
        Matrix.__super__.constructor.call(this, object);

        var config = object;
        switch (config.response_type) {
          case 'single':
            config.input_type = 'radio';
            break;
          case 'multiple':
            config.input_type = 'checkbox';
            break;
          case 'text':
            config.input_type = 'text';
            break;
          case 'int':
            config.input_type = 'number';
            break;
          case 'real':
            config.input_type = 'text';
            break;
          default:
            config.input_type = 'radio';
        }
      },

      render: function render() {
        Matrix.__super__.render.call(this);

        if (this._object.displaymax) {
          this._initDisplaymax();
        }

        if (this._object.category_errors.length > 0) {
          this.highlight_required_rows();
        }

        // console.log("Matrix JS Render", this)

        // Pass our config object to
        // the `view` method which
        // will call the `render` methods
        // of the specified `render` value
        this.view(this._object);
      },

      _initDisplaymax: function _initDisplaymax() {
        var settings = _.pick(this._object, [
          'phase_pos',
          'phase_max',
          'required',
          'soft_required',
          'required_message',
          'ranges_start',
          'ranges_stop',
        ]);

        this._displayMaxHelper = new MatrixDisplayMaxHelper(settings);
        this._displayMaxHelper.applySideEffects();
        this._displayMaxHelper.highlight_required_rows =
          this.highlight_required_rows.bind(this);
        this._displayMaxHelper.displaymax = this._object.displaymax;

        //Uncomment it when it's transpose is needed
        this._displayMaxHelper.transpose = this._object.transpose;
      },

      view: function (object) {
        switch (object.render) {
          case 'flashcards':
            new MatrixFlashcards(this).render();
            break;
          case 'cards':
            new CardSort().render(this, object);
            break;
          case 'input_box':
            new InputBox().render(this, object);
            break;
          default:
            // The default rendering is that of a 'table'
            // which needs no further processing
            break;
        }
      },

      _handleShowOnSkip: function _handleShowOnSkip() {
        var self = this;
        var header_el,
          row_el,
          elms = self._object.show_on_skip || [];
        for (var i = 0; i < elms.length; i++) {
          header_el = $('.matrix-header-response-' + elms[i].code);
          row_el = $('input[data-column="' + elms[i].code + '"]').closest('td');

          if (!$('.alert-error').is(':visible')) {
            header_el.hide();
            row_el.hide();
          }
        }

        // interrupt the next click event to check for conditionals
        if (mainNav) {
          mainNav.nextButton.bind(
            'click keypress',
            $.proxy(this, '_checkConditionals')
          );
        }
      },

      _checkConditionals: function _checkConditionals(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this,
          el,
          elms = self._object.show_on_skip || [];

        var responses = $('#' + self._object.element_id + ' input');
        var answered = _.filter(responses, function (i) {
          return $(i).is(':checked');
        });
        var hidden = _.filter(elms, function (i) {
          return $(
            'tbody[data-phase]:not(:hidden) input[data-column="' + i.code + '"]'
          ).is(':hidden');
        });

        if (self.isPassed(self, answered, elms, hidden)) {
          if (self._object.phase_count === 1) {
            return click_next({ key: ev.key, type: ev.type });
          } else {
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').hide();
            }
            return this._displayMaxHelper.pgrid_next(ev);
          }
        }

        // show the message and extra response option
        for (var i = 0; i < elms.length; i++) {
          el = $('input[data-column="' + elms[i].code + '"]').closest('td');
          if (!el.is(':visible')) {
            $('.matrix-header-response-' + elms[i].code).show();
            el.show();
            if ($('.alert-error').is(':visible')) {
              $('.alert-error').text(self._object.required_text);
            } else {
              $('.question-text').after(
                '<div class="alert alert-error">' +
                  this._object.required_text +
                  '</div>'
              );
            }
          }
        }
      },

      highlight_required_rows: function highlight_required_rows(errors) {
        if (errors) {
          this._object.category_errors = errors;
        }
        var that = this,
          found;
        if ($('.mobile').length > 0 && that._object.displaymax) {
          $('.accordion-group').removeClass('accordion-group-error');
          $.each(this._object.category_errors, function (idx, varname) {
            found = _.find(that._object.category_options, { varname: varname });
            if (found) {
              $('#r-' + varname).addClass('accordion-group-error');
            }
          });
        } else {
          // desktop
          $(
            '.matrix-layout tbody tr, .matrix-layout tbody td, .matrix-layout thead th'
          ).removeClass('error');
          $.each(this._object.category_errors, function (idx, varname) {
            found = _.find(that._object.category_options, { varname: varname });
            if (found) {
              try {
                if (!that._object.transpose) {
                  $('[name*="' + found.name + '"]')
                    .first()
                    .closest('tr')
                    .addClass('error');
                } else {
                  $('[name*="' + found.name + '"]')
                    .closest('td')
                    .addClass('error');
                  $('thead th[class*="' + varname + '"').addClass('error');
                }
              } catch (e) {}
            }
          });
        }
      },
    },
    {
      types: ['matrix'],
      views: ['matrix'],
    }
  );

  Matrix.register();
  widgets.Matrix = Matrix;

  var MatrixDisplayMaxHelper = BaseClass.extend({
    constructor: function GridDisplayMaxHelperConstructor(settings) {
      $.extend(this, settings);
      this.displaymax_soft_prompts = 0;
    },

    applySideEffects: function applySideEffects() {
      this.update_paged_buttons();
      mainNav.nextButton.bind('click keypress', $.proxy(this, 'pgrid_next'));
      mainNav.backButton.bind('click keypress', $.proxy(this, 'pgrid_back'));
    },

    update_paged_buttons: function update_paged_buttons() {
      if (this.back_allowed == undefined) {
        this.back_allowed = $('#back_button').css('display') != 'none';
      }
      var button_states = {};
      button_states.next_button = true;
      button_states.back_button = this.back_allowed;
      var pos = this.phase_pos;
      if (pos > 0 && this.back_allowed) {
        button_states.back_button = true;
      }
      set_nav_button_visibility(button_states);
    },

    pgrid_next: function pgrid_next(ev) {
      if (
        ev &&
        ev.type === 'keypress' &&
        ev.key !== ' ' &&
        ev.key !== 'Enter'
      ) {
        return;
      }
      var phase_rows = this.get_phase_rows();
      var that = this,
        errors = false,
        r_start = parseFloat(this.ranges_start),
        r_stop = parseFloat(this.ranges_stop);
      try {
        phase_rows.each(function () {
          var phase_row = $(this),
            inputs = phase_row.find('input:text');

          inputs.each(function () {
            if ($(this).val() < r_start || $(this).val() > r_stop) {
              that.display_range_validation_message();
              errors = true;
            }
          });
        });
      } catch (e) {}
      if (errors) {
        return;
      }

      var cur = this.phase_pos;
      this.clear_range_validation_message();
      this.clear_required_message();
      if (!errors && this.required_is_satisfied()) {
        this.displaymax_soft_prompts = 0;
        if (cur + 1 > this.phase_max) {
          click_next({ key: ev.key, type: ev.type });
        } else {
          if (this.displaymax && this.transpose) {
            $("td[data-phase='" + cur + "']").css('display', 'none');
            $("th[data-phase='" + cur + "']").css('display', 'none');
            this.phase_pos++;
            cur++;
            $("td[data-phase='" + cur + "']").css('display', '');
            $("th[data-phase='" + cur + "']").css('display', '');
          } else {
            $('#phase-' + cur).css('display', 'none');
            this.phase_pos++;
            cur++;
            $('#phase-' + cur).css('display', '');
          }
          this.update_paged_buttons();
        }
      } else if (!errors) {
        this.display_required_message();
      }
      return false;
    },

    pgrid_back: function pgrid_back(ev) {
      if (
        ev &&
        ev.type === 'keypress' &&
        ev.key !== ' ' &&
        ev.key !== 'Enter'
      ) {
        return;
      }
      var cur = this.phase_pos;
      if (cur - 1 < 0) {
        click_back({ key: ev.key, type: ev.type });
      } else {
        $('#phase-' + cur).css('display', 'none');
        this.phase_pos--;
        cur--;
        $('#phase-' + cur).css('display', '');
        this.update_paged_buttons();
      }
      return false;
    },

    get_phase_rows: function get_phase_rows() {
      if (this.displaymax && this.transpose) {
        return $("td[data-phase='" + this.phase_pos + "']");
      }
      return $('tr, .accordion-group', '#phase-' + this.phase_pos);
    },

    display_required_message: function display_required_message() {
      var template = Gryphon.templates['displaymax-required-message'];
      var data = { required_message: this.required_message };
      $('.matrix-container').before(template(data));
      if (this.soft_required) {
        this.displaymax_soft_prompts = 1;
      }

      var that = this;
      var varname,
        phase_rows = this.get_phase_rows();
      var isCategorical = !!phase_rows.find('input:radio, input:checkbox')
        .length;
      // reset our category_errors
      var errors = [],
        answered = [];
      phase_rows.each(function () {
        var phase_row = $(this);

        if (isCategorical) {
          if (!phase_row.find('input:checked').length) {
            varname = phase_row.prop('id').slice(2);
            if (
              errors.indexOf(varname) == -1 &&
              answered.indexOf(varname) == -1
            ) {
              errors.push(varname);
            }
          } else {
            varname = phase_row.prop('id').slice(2);
            if (answered.indexOf(varname) == -1) {
              answered.push(varname);
            }
          }
        } else {
          var inputs = phase_row.find('input:text');
          var answers = 0;
          inputs.each(function () {
            if ($(this).val() !== '') {
              answers += 1;
            }
          });
          if (answers !== inputs.length) {
            varname = phase_row.prop('id').slice(2);
            if (errors.indexOf(varname) == -1) {
              errors.push(varname);
            }
          }
        }
      });

      if (errors.length > 0) {
        this.highlight_required_rows(errors);
      }
    },

    display_range_validation_message:
      function display_range_validation_message() {
        if (!$('.alert-validation').is(':visible')) {
          var tmp =
            '<div class="alert alert-error alert-validation">Please provide a whole number' +
            ' between ' +
            this.ranges_start +
            ' and ' +
            this.ranges_stop +
            '.</div>';
          $('.matrix-container').before(tmp);
        }
      },

    clear_range_validation_message: function clear_range_validation_message() {
      $('.alert-validation').remove();
    },

    required_is_satisfied: function required_is_satisfied() {
      var required = this.required === 'HARD' ? true : false;
      var soft_required = this.soft_required;
      if (required || (soft_required && !this.displaymax_soft_prompts)) {
        if (this.selected_rows_count() < this.visible_rows_count()) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    selected_rows_count: function selected_rows_count() {
      var phase_rows = this.get_phase_rows();
      var isCategorical = !!phase_rows.find('input:radio, input:checkbox')
        .length;
      var num_selected = 0;
      phase_rows.each(function () {
        var phase_row = $(this);

        if (isCategorical) {
          if (phase_row.find('input:checked').length) {
            num_selected++;
          }
        } else {
          var inputs = phase_row.find('input:text');
          var answers = 0;
          inputs.each(function () {
            if ($(this).val() !== '') {
              answers += 1;
            }
          });
          if (answers === inputs.length) {
            num_selected += 1;
          }
        }
      });
      return num_selected;
    },

    visible_rows_count: function visible_rows_count() {
      if (this.displaymax && this.transpose) {
        // If transposed and displaymax, we need to divide the number of table rows
        // with the total # of table cells to determine visible columns
        return (
          this.get_phase_rows().length /
          $('table.matrix-layout tbody > tr').length
        );
      }
      return this.get_phase_rows().length;
    },

    clear_required_message: function clear_required_message() {
      $('#displaymax-required-error').remove();
    },
  });
})((Gryphon.widgets = Gryphon.widgets || {}), Gryphon.BaseClass);
