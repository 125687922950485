(function ($) {
  var MainNav = function (id) {
    var button = function (id) {
      return {
        id: id,
        show: function () {
          $('#' + this.id)
            .show()
            .attr('aria-disabled', false)
            .attr('tabindex', '0');
          if (this.id === 'skip_button') {
            $('#' + this.id).css('display', 'block');
          } else {
            $('#' + this.id).css('display', 'inline-block');
          }
          this.enable();
        },
        hide: function () {
          $('#' + this.id)
            .hide()
            .attr('aria-disabled', true)
            .attr('tabindex', '-1');
        },
        enable: function () {
          $('#' + this.id)
            .prop('disabled', false)
            .attr('tabindex', '0');
        },
        disable: function () {
          $('#' + this.id)
            .prop('disabled', true)
            .attr('tabindex', '-1');
        },
        bind: function (evnt, func) {
          $('#' + this.id)
            .unbind()
            .bind(evnt, func);
        },
        unbind: function () {
          $('#' + this.id).unbind();
        },
        blur: function () {
          $('#' + this.id).blur();
        },
        focus: function () {
          $('#' + this.id).focus();
        },
        prop: function (attribute, value = true) {
          $('#' + this.id).attr(attribute, value);
        },
      };
    };

    return {
      id: id,
      joinButton: button('join_button'),
      backButton: button('back_button'),
      nextButton: button('next_button'),
      skipButton: button('skip_button'),
      hide: function () {
        $('#' + this.id).hide();
      },
      show: function () {
        $('#' + this.id).show();
      },
      disable: function () {
        this.backButton.disable();
        this.nextButton.disable();
        this.skipButton.disable();
      },
      enable: function () {
        this.backButton.enable();
        this.nextButton.enable();
        this.skipButton.enable();
      },
      unbind: function () {
        this.backButton.unbind();
        this.backButton.disable();

        this.nextButton.unbind();
        this.nextButton.disable();

        this.skipButton.unbind();
        this.skipButton.disable();
      },
      blur: function () {
        this.backButton.blur();
        this.nextButton.blur();
        this.skipButton.blur();
      },
    };
  };
  window.MainNav = MainNav;
})(jQuery);
