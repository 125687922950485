(function ($) {
  var RankingWidget = Gryphon.BaseClass.extend({
    constructor: function RankingWidgetConstructor(
      selector,
      inputId,
      data,
      options
    ) {
      $.uiBackCompat = true;
      this.settings = $.extend(
        {
          mobile_max_width: Gryphon.widgets.MEDIA_SKINNY_MAX_WIDTH,
        },
        options
      );

      var element = $(selector);

      var data_valid =
        typeof data === 'object' &&
        inputId !== undefined &&
        data.responseOptions !== undefined &&
        data.rankingNumbers !== undefined;

      if (!data_valid) {
        // Return an error if we don't get the objects we need,
        // and stop the init.
        $.error(
          'RankingWidget requires the following data: inputId,' +
            'responseOptions, and rankingNumbers passed as an object ' +
            "from the template when you instantiate it. I don't see at " +
            'least one of these passed in. Try again!'
        );
      } else if (typeof selector !== 'string') {
        // Return an error if containers aren't defined as we want them.
        $.error(
          'RankingWidget requires you to define the class or ID of ' +
            'rankingWidget container ID. This should be a STRING.'
        );
      }

      // Fill up the `dataModel` with the ranking and slot values
      this.dataModel = new DataModel().init(
        data.currentState,
        data.responseOptions,
        data.rankingNumbers,
        this.settings
      );

      // Connect the `dataSaver` to the input DOM element and the `dataModel`.
      this.dataSaver = new DataSaver().init(
        this.dataModel,
        $('[name="' + inputId + '"]')
      );

      this.windowWidthController = new WindowWidthController();

      // Initialize the views!
      this.wideView = new WideRankingWidgetView().init(
        element,
        this.dataModel,
        this.windowWidthController
      );
      this.skinnyView = new SkinnyRankingWidgetView().init(
        element,
        this.dataModel,
        this.windowWidthController
      );

      // Initialize the `WindowWidthController`
      this.windowWidthController.init(
        this.settings.mobile_max_width,
        this.settings.wide_view
      );

      this.justDropped = false;
    },

    destroy: function destroy() {
      this.wideView.destroy();
      this.skinnyView.destroy();
      this.windowWidthController.destroy();
    },
  }); // end init

  var DataModel = Gryphon.BaseClass.extend({
    // Initialize the `RankingWidget`
    //
    //     `gryphonState` contains the ranking state data.
    //     `responseOptions` contains the list of options.
    //     `rankingNumbers` contains the list of rank slots.
    //     `options` overrides default settings.
    init: function (gryphonState, responseOptions, rankingNumbers, options) {
      // This is used to take the responseOptions and currentState
      // and create the rankedObjects and unrankedObjects arrays.
      //
      // **Examples of data from Gryphon:**
      //
      //     currentState: {"1":1,"2":null,"3":null,"4":null,"5":null}
      //     responseOptions: [{ code: 1, text: "Rank One"},
      //                       { code: '2', text: "Rank Two"}]
      //     ranking_numbers: ["1", "2", "3"]
      //
      // widgetOptions is an optional argument. If provided it should be an
      // object, which may include these values:
      //
      //     `displaceUp`: `bool`.  defaults to `false`.  If `true`, will bump things
      //     up instead of down when you rank one thing on top of something
      //     else.
      //
      //     `unrank`: bool.  defaults to `true`.  If `false`, disallow unranking
      //     things.
      //
      //     NOTE: If you have more `widgetOptions` than slots, and you set `unrank` to
      //     `false`, the panelist will still be able to pop things off the
      //     rankings by ranking other `widgetOptions` on top of them.

      this.settings = $.extend(
        {
          displaceUp: false,
          unrank: true,
        },
        options
      );

      var slots = (this.slots = []);
      // Add an empty slot object for each slotnum we've been told to make.
      $.each(rankingNumbers, function (idx, val) {
        slots.push({ label: val, item: null });
      });

      this.unranked = responseOptions;
      this.allResponseOptions = responseOptions;

      // `gryphonState` only has a value when the question was previously saved.
      // Such as when answering a question but then going back to it later.
      this.setState(gryphonState);

      return this;
    },

    setState: function (state) {
      if (state) {
        // Flag to indicate whether state looks like a DK response.
        // For the moment, it's for the whole question, not per item.
        // Assume it is dk, and negate if anything suggests otherwise.
        var looks_like_dk = true;
        var dk_value = this.settings.dk_value;

        var rankItem = $.proxy(this, 'rankItem');
        $.each(state, function (code, rank) {
          // If any item is not set to the dk_value, the question cannot
          // be dk. This is because for the moment, dk is for the whole
          // question, not per item.
          if (rank != null && rank !== dk_value) {
            looks_like_dk = false;
            code = parseInt(code, 10);
            rankItem(code, rank - 1, true);
          } else if (rank === null) {
            looks_like_dk = false;
          }
        });

        if (this.settings.dk && looks_like_dk) {
          this.checkDK();
        }
      }
    },

    getUnranked: function () {
      // Views should use this function to get the array of unranked items.
      // returns an array like this:
      //
      //     [
      //         {text: 'BYU Cougars', code: '1'},
      //         {text: 'Oregon Ducks', code: '4'},
      //     ]
      return this.unranked;
    },

    getState: function () {
      // From our slots and unranked lists, build up a single object in the
      // shape that Gryphon wants it, like this:
      //
      //     {
      //         '1': null,
      //         '2': 4,
      //     }

      var state = {};

      // Add all the `slots` to the state object.
      $.each(this.slots, function (idx, val) {
        if (val.item) {
          state[val.item.code] = idx + 1;
        }
      });

      // Now loop otber unranked items and put them in the object too.
      var is_dk = this.dk;
      var dk_value = this.settings.dk_value;
      $.each(this.unranked, function (idx, item) {
        state[item.code] = is_dk ? dk_value : null;
      });

      return state;
    },

    getItem: function (code) {
      // Given a `code`, look through the slots and the `unranked` items to get
      // the item that corresponds to that code
      var inUnranked = $.grep(this.unranked, function (item, idx) {
        return item.code === code;
      });

      if (inUnranked.length) {
        return inUnranked[0];
      }

      // it's not in the unranked list.  Check the slots.
      var inSlots = $.grep(this.slots, function (slot, idx) {
        return slot.item !== null && slot.item.code === code;
      });

      if (inSlots.length) {
        return inSlots[0].item;
      }

      // item doesn't exist in slots or unranked list.  return null
      return null;
    },

    checkDK: function () {
      this.lastState = this.getState();
      this.dk = true;

      var unrankItem = $.proxy(this, 'unrankItem');
      $.each(this.slots, function (idx, slot) {
        if (slot.item) {
          unrankItem(slot.item.code, undefined, true, true);
        }
      });
      $(this).trigger('rankchange');
    },

    unCheckDK: function () {
      this.setState(this.lastState);
      this.dk = false;
      $(this).trigger('rankchange');
    },

    rankItem: function (code, slotnum, silent) {
      // NOTE: slotnum is 0-indexed

      var item = this.getItem(code);
      var old_item, newSlotNum;
      this.dk = false;

      // Do we actually have a slot for `slotnum`?  If not, then just make sure
      // `item` is removed.
      if (slotnum >= this.slots.length || slotnum < 0) {
        // Return here so that only the `unrankItem` event gets fired,
        // and not one for an addRank that didn't really happen.
        return this.unrankItem(item.code, undefined, true, true);
      }

      // If the item is already in a slot, set that slot's item to null.
      $.each(this.slots, function (idx, val) {
        if (val.item === item) {
          val.item = null;
        }
      });

      // Is there already something in that slot? If so, then recurse.
      old_item = this.slots[slotnum].item;
      if (old_item !== null) {
        newSlotNum = this.settings.displaceUp ? -1 : 1;
        this.rankItem(old_item.code, slotnum + newSlotNum, true);
      }

      // Set the `item` in the slot
      this.slots[slotnum].item = item;

      // Remove the `item` from the unranked list
      this.unranked = $.grep(
        this.unranked,
        function (rankOption, idx) {
          return rankOption.code === item.code;
        },
        true
      );

      if (!silent) {
        $(this).trigger('rankchange');
      }
    },

    unrankItem: function (code, unrankedPosition, force, silent) {
      // unrank a ranked item, optionally passing in the position in the
      // unranked list where the item should be placed.
      //
      // pass the 'force' argument to unrank the item even if the widget
      // had the 'unrank' option set to false.

      if (this.settings.unrank === true || force === true) {
        var item = this.getItem(code);

        if (
          unrankedPosition >= this.unranked.length ||
          unrankedPosition === undefined
        ) {
          // if the unrankedPosition is higher than the number of
          // elements in the list, or no unrankedPosition is passed,
          // then just stick the unranked item at the bottom of the
          // unranked list.
          this.unranked.push(item);
        } else {
          this.unranked.splice(unrankedPosition, 0, item);
        }

        // now find the slot the item was in, and set slot.item = null;
        $.each(this.slots, function (idx, slot) {
          if (slot.item !== null && slot.item.code === item.code) {
            slot.item = null;
          }
        });

        if (!silent) {
          $(this).trigger('rankchange');
        }
      }
    },
  }); // end DataModel

  // THE WINDOW WIDTH CONTROLLER ------------------//
  // used to manage the changes in screen width.
  var WindowWidthController = Gryphon.BaseClass.extend({
    init: function (maxWidth, wideView) {
      var mode;

      if ($('.iexpl').length === 0) {
        this.triggerNewView = function () {
          // Case 49935
          if (wideView === 'select') {
            // show skinny view
            mode = 'skinny';
            $(this).trigger('goSkinny');
          } else if ($(window).width() > maxWidth && mode !== 'wide') {
            // show wide view
            mode = 'wide';
            $(this).trigger('goWide');
          } else if ($(window).width() <= maxWidth && mode !== 'skinny') {
            // show skinny view
            mode = 'skinny';
            $(this).trigger('goSkinny');
          }
        };

        $(window).on('resize.rank', $.proxy(this.triggerNewView, this));

        // run on controller view change call to start.
        this.triggerNewView();
      } else {
        // if it's IE, just show the wide view.
        $(this).trigger('goWide');
      }

      return this;
    },

    destroy: function destroy() {
      $(window).off('resize.rank');
    },
  }); // end WindowWidthController

  // THE DATA SAVING OBJECT ------------------//
  // Model to save data to a specified node object.
  // Attaches to an instance of the dataModel, and connects it to the domnode
  // (in this case, the input element).

  var DataSaver = Gryphon.BaseClass.extend({
    init: function (model, domnode) {
      this.model = model;
      this.inputNode = domnode;

      // bind our 'updateNode' functionto the model's "rankchange" event
      $(this.model).on('rankchange', null, $.proxy(this, 'updateNode'));

      // Make sure we set the input value on load.
      this.updateNode();

      return this;
    },

    updateNode: function () {
      // iterates over the ranked and unranked objects and parrots
      // the results into our inputNode object.
      var stringValue;

      stringValue = JSON.stringify(this.model.getState());
      this.inputNode.val(stringValue);
    },
  });

  var RankingWidgetView = Gryphon.BaseClass.extend({
    init: function (element, dataModel, widthController) {
      this.element = element;
      this.dataModel = dataModel;
      this.viewContainer = $(
        this.getTemplateHtml('viewcontainer', {
          identifier: this.identifier,
          dk: this.dataModel.settings.dk,
        })
      ).appendTo(this.element);

      this.widthController = widthController;
      $(this.widthController)
        .on('goWide', $.proxy(this, this.mode === 'Wide' ? 'show' : 'hide'))
        .on('goSkinny', $.proxy(this, this.mode === 'Wide' ? 'hide' : 'show'));
      $(this.dataModel).on('rankchange', null, $.proxy(this, 'update'));

      return this;
    },

    getTemplateHtml: function (name, data) {
      var key_with_mode = 'rank_' + this.mode.toLowerCase() + '_' + name;
      var key_without_mode = 'rank_' + name;
      var key =
        key_with_mode in Gryphon.templates ? key_with_mode : key_without_mode;
      return Gryphon.templates[key](data);
    },

    orderedItems: function (collection) {
      var items = this.dataModel.settings.reverseSlots
        ? collection.slice().reverse()
        : collection;
      return items;
    },

    render: function () {
      var selectionContainer = $('#rankSelectionBlock' + this.mode);
      this.active_view = $('#wideView.rankingView').is(':visible')
        ? 'Wide'
        : 'Skinny';
      this.should_unlock =
        (this.dataModel.settings.min > 0 &&
          this.dataModel.settings.required === 'HARD') ||
        this.dataModel.settings.required !== 'HARD';
      this.unlock_index = {};

      this.renderSelectionBlock(selectionContainer);
      this.renderPlacementBlock();
      this.renderDK();

      this.bindDropEvent(this.viewContainer.find('.rank'));
      this.bindEvents();

      global.removeFocusBorderSelect();

      this.widgetValidations();
    },

    widgetValidations: function () {
      // gaps in between answers
      var self = this;
      var gapsIndex = [];
      var reversedSlots = Array.from(this.dataModel.slots).reverse();
      var firstAnswerDetected = false;

      reversedSlots.forEach(function (slot, index) {
        if (slot.item && !firstAnswerDetected) {
          firstAnswerDetected = true;
        } else if (!slot.item && firstAnswerDetected) {
          var newIndex = self.dataModel.slots.length - 1 - index;
          $('.rank').eq(newIndex).addClass('cell-error');
          $('.rankPlace select').eq(newIndex).addClass('dropdown-error');
          gapsIndex.push(newIndex);
        }
      });
    },

    // Should override in child classes that want draggable items.
    renderSelectionBlock: function (selectionContainer) {
      this._renderSelectionBlock(selectionContainer, false);
    },

    _renderSelectionBlock: function (selectionContainer, draggable) {
      var self = this;

      // We want a clean slate, make sure the parent containers are empty.
      selectionContainer.empty();
      // create a view object for each selectionObject
      $.each(this.orderedItems(this.dataModel.unranked), function () {
        var html = self.getTemplateHtml('selection', this);
        selectionContainer.append(
          "<div class='rankSelect-bg' data-code='" + this.code + "'></div>"
        );
        selectionContainer.append(html);
        if (draggable) {
          self.bindDragEvent(
            selectionContainer.find('#source-slot-' + this.code),
            { revert: true }
          );
        }
      });
      setTimeout(function () {
        $.each(selectionContainer.find('.rankSelect-bg'), function (idx) {
          var zIndexBg = $(this).css('z-index'),
            parentSelect = $(this).next('.rankSelect'),
            zIndexParent = parentSelect.css('z-index');

          // Adjust z-index to ensure accessibility menu is not covered
          $(this).css('z-index', zIndexBg - idx);
          parentSelect.css('z-index', zIndexParent - idx);

          var code = $(this).data('code'),
            offset =
              $('#source-slot-' + code).offset().top -
              selectionContainer.offset().top -
              16,
            height = $(this).next('.rankSelect').height();
          if (offset >= 0) {
            $(this)
              .css('top', offset + 'px')
              .css('height', height - 2 + 'px'); // account for borders
          }
        });
      }, 300);
    },

    renderPlacementBlock: function () {
      var self = this;

      var placementContainer = this.viewContainer.find(
        '#rankPlacementBlock' + this.mode
      );

      // We want a clean slate, make sure the parent containers are empty.
      if (placementContainer.children().length > 0) {
        placementContainer.empty();
      }
      // accessible dropdown menu only used in 'desktop' / 'wide' view
      if (this.mode == 'Wide') {
        $('.accessible-dropdown').empty();
      }

      // Append the selectionObjects to the selectionContainer(s)
      // Create a view object for each placementObject
      $.each(this.orderedItems(this.dataModel.slots), function (idx) {
        // Hackily feed in selection_help translated string
        this.selection_help = self.dataModel.settings.selection_help;
        this.small_selection_placeholder =
          self.dataModel.settings.small_selection_placeholder;
        self.label = this.label;
        self.item = this.item;

        var placementHtml = self.getTemplateHtml('placement', this);
        placementContainer.append(placementHtml);

        // add rank placement options to accessibility menu for desktop / wide view
        if (self.mode == 'Wide') {
          $.each(self.orderedItems(self.dataModel.unranked), function () {
            var selectionContainer = $('#source-slot-' + this.code),
              menu = selectionContainer.find('.accessible-dropdown'),
              code = this.code,
              label = self.label;
            if (!self.item) {
              menu.append(
                '<li role="menuitem" data-source="source-slot-' +
                  code +
                  '" data-rank="rank-drop-' +
                  label +
                  '">Rank ' +
                  label +
                  '</li>'
              );
            }
          });
        }

        if (this.item !== null) {
          var selection = self.dataModel.getItem(this.item.code);
          var selectionHtml = self.getTemplateHtml('selection', selection);

          placementContainer
            .find('#rank-drop-' + this.label)
            .append(selectionHtml)
            .addClass('hasRank');

          self.bindDragEvent(
            self.viewContainer.find('#source-slot-' + this.item.code)
          );
          self.viewContainer
            .find('#source-slot-' + this.item.code)
            .addClass('addedToRank');

          // Add reset to accessibility menu for 'placed' elements
          if (self.mode == 'Wide') {
            self.viewContainer
              .find('#source-slot-' + this.item.code)
              .find('.accessible-dropdown')
              .append(
                '<li role="menuitem" data-source="source-slot-' +
                  this.item.code +
                  '">Reset rank</li>'
              );

            // Adjust z-index to ensure accessibility menu is not covered
            var placed = self.viewContainer.find(
              '#source-slot-' + this.item.code
            );
            placed.css('z-index', parseInt(placed.css('z-index')) - idx);
          }
        }
      });
    },

    renderDK: function () {
      if (this.dataModel.settings.dk) {
        var dk = this.getTemplateHtml('dk', {
          checked: this.dataModel.dk,
          dk_text: this.dataModel.settings.dk_text,
        });
        this.viewContainer.find('.rank-dk').empty().append(dk);

        var checkDK = $.proxy(this, 'checkDK');
        var unCheckDK = $.proxy(this, 'unCheckDK');
        this.viewContainer
          .find('.dk-check')
          .on('change', function onDKChange(event) {
            if (this.checked) {
              checkDK();
            } else {
              unCheckDK();
            }
          })
          .uniform();
      }
    },

    bindEvents: $.noop,

    unbindEvents: $.noop,

    checkDK: function checkDK() {
      this.dataModel.checkDK();
    },

    unCheckDK: function unCheckDK() {
      this.dataModel.unCheckDK();
    },

    rankSelection: function (rankSlot, selectionObject) {
      var selectionId = selectionObject.prop('id'); //choices
      var rankDropId = rankSlot.prop('id'); //placeholder for choices

      var code = parseInt(selectionId.slice('source-slot-'.length), 10);
      var slotnum = parseInt(rankDropId.slice('rank-drop-'.length), 10) - 1;

      this.dataModel.rankItem(code, slotnum);
    },

    rankDropdownSelection: function (code, slotnum) {
      this.dataModel.rankItem(code, slotnum);
    },

    unrankSelection: function (selectionNode) {
      // see if something is being removed
      // from a rankslot back to the selectionContainer
      var code = selectionNode.prop('id');
      var unranked = this.dataModel.getUnranked();
      code = parseInt(code.slice('source-slot-'.length), 10);

      var selectObj = $.grep(unranked, function (item, idx) {
        return item.code === code;
      });

      if (selectObj.length === 0 && !this.justDropped) {
        // if this object HAS been ranked, but there's no drophover
        // to indicate another droptarget, unrank it and send it
        // back to the corral.
        this.dataModel.unrankItem(code);
      }
      this.justDropped = false;
      this.render();
    },

    unrankDropdownSelection: function (slotnum) {
      //Get the item stored in the slot
      var slots = this.dataModel.slots;

      var slot = slots[slotnum];
      if (slot.item) {
        this.dataModel.unrankItem(slot.item.code);
      }
      this.render();
    },

    fillDropdownOptions: function () {
      //$('.rank-select');
      var self = this;
      var unranked = this.dataModel.getUnranked();
      var slots = this.dataModel.slots;
      var numOfSlots = slots.length;
      var rankSelections = this.dataModel.getState();
      /**
       *
       * Logics
       * At first - show all the unranked choice if nothing is selected so far
       *
       * Once something is selected
       *  the dropdown option is still having the full list
       *  when a particular option is selected,
       *     > bind that action as unrank
       *  else
       *     > bind that action as rank
       *
       *
       */

      function canAppend(id, code) {
        return rankSelections[code] === null || rankSelections[code] === id;
      }

      function hasImage(text) {
        return text.includes('<img ') && text.includes('src');
      }

      //Loop for the number of available slots for rank
      for (var i = 0; i < numOfSlots; i++) {
        //Is slot i-th has ranked items
        var slot = slots[i];

        // Default settings
        $(`#rank-dropdown-${i + 1}`).removeClass('selected');

        if (this.dataModel.dk) {
          $(`#rank-dropdown-${i + 1}`)
            .prop('disabled', true)
            .addClass('diabledDropdown');
        } else if (
          this.dataModel.settings.reverseSlots &&
          slot.item == null &&
          numOfSlots.length !== i
        ) {
          $(`#rank-dropdown-${i}`)
            .prop('disabled', true)
            .addClass('diabledDropdown');
          if (
            slots[i] &&
            slots[i].item === null &&
            slots[i - 1] &&
            slots[i - 1].item !== null
          ) {
            $(`#rank-dropdown-${i}`)
              .prop('disabled', false)
              .removeClass('diabledDropdown');
          }
        } else if (
          slot.item === null &&
          slots[i + 1] &&
          slots[i + 1].item === null
        ) {
          $(`#rank-dropdown-${i + 2}`)
            .prop('disabled', true)
            .addClass('diabledDropdown');
        }

        $(`#rank-dropdown-${i + 1}`).append(
          '<div>' +
            this.dataModel.settings.small_selection_placeholder +
            '</div>'
        );
        $(`#rank-dropdown-${i + 1}`).append(
          '<span aria-hidden="true"><span>&#43;</span></span>'
        );
        $(`#rank-dropdown-${i + 1} > span`).off('click');

        if (!this.dataModel.settings.unrank && slot.item !== null) {
          $(`#rank-dropdown-${i + 1} > span`).remove();
        }

        if (slot.item != null) {
          $.each(
            this.orderedItems(this.dataModel.allResponseOptions),
            function () {
              if (
                slot.item != null &&
                slot.item.code == this.code &&
                canAppend(i + 1, this.code)
              ) {
                // This block comes when option is selected
                // Marks the dropdown button selected
                $(`#rank-dropdown-${i + 1}`).addClass('selected');
                // Appends selection and adds remove icon button
                $(`#rank-dropdown-${i + 1} > div`).html(
                  hasImage(this.text)
                    ? this.text
                    : Gryphon.util.decode_string(this.text)
                );
                $(`#rank-dropdown-${i + 1} > span`).html(
                  '<span>&times;</span>'
                );
                //Appends option in modal (selected)
                $(`#rank-modal-${i + 1} .modal-body`).append(
                  `<div id="rank-dropdown-option-${i + 1}-${
                    this.code
                  }" class="rank-dropdown-option selected"><div><input id="rank-dropdown-option-${
                    i + 1
                  }-${this.code}-input" type="radio" value="${
                    this.code
                  }" checked></div><label for="rank-dropdown-option-${i + 1}-${
                    this.code
                  }-input">${
                    hasImage(this.text)
                      ? this.text
                      : Gryphon.util.decode_string(this.text)
                  }</label></div>`
                );
                // Event for 'x' button to remove selection
                $(`#rank-dropdown-${i + 1} > span`).on('click', function (e) {
                  e.stopPropagation();
                  e.preventDefault();
                  self.unrankDropdownSelection(
                    e.currentTarget.parentElement.id.slice(
                      'rank-dropdown-'.length
                    ) - 1
                  );
                });
              } else if (canAppend(i + 1, this.code)) {
                //Appends option in modal (not-selected)
                $(`#rank-modal-${i + 1} .modal-body`).append(
                  `<div id="rank-dropdown-option-${i + 1}-${
                    this.code
                  }" class="rank-dropdown-option"><div><input id="rank-dropdown-option-${
                    i + 1
                  }-${this.code}-input" type="radio" value="${
                    this.code
                  }"></div><label for="rank-dropdown-option-${i + 1}-${
                    this.code
                  }-input">${
                    hasImage(this.text)
                      ? this.text
                      : Gryphon.util.decode_string(this.text)
                  }</label></div>`
                );
              }
            }
          );
        } else {
          $.each(
            this.orderedItems(this.dataModel.allResponseOptions),
            function () {
              if (canAppend(i + 1, this.code)) {
                //Appends option in modal (not-selected -- start)
                $(`#rank-modal-${i + 1} .modal-body`).append(
                  `<div id="rank-dropdown-option-${i + 1}-${
                    this.code
                  }" class="rank-dropdown-option"><div><input id="rank-dropdown-option-${
                    i + 1
                  }-${this.code}-input" type="radio" value="${
                    this.code
                  }"></div><label for="rank-dropdown-option-${i + 1}-${
                    this.code
                  }-input">${this.text}</label></div>`
                );
              }
            }
          );
        }
      }

      // Event for selecting respective option
      $(`.rank-dropdown-option`).on('click', function (e) {
        var option = $(`#${e.currentTarget.id}`);
        if (option.hasClass('selected')) {
          option.removeClass('selected');
          option.find('input:radio').prop('checked', false);
        } else {
          $('#rankPlacementBlockSkinny .rank-dropdown-option').removeClass(
            'selected'
          );
          $('#rankPlacementBlockSkinny input:radio').prop('checked', false);
          option.addClass('selected');
          option.find('input:radio').prop('checked', true);
        }
        var slotnum = e.currentTarget.id.split('-')[3];
        var modal = $(`#rank-modal-${slotnum}`);
        $.each(modal.find('.modal-body').children(), function (index, element) {
          if ($(element).hasClass('selected')) {
            value = $(element).find('input:radio').val();
          }
        });
        modal.modal('toggle');
        if (value) {
          self.rankDropDownSelection(parseInt(value), slotnum - 1);
        } else {
          self.unrankDropdownSelection(slotnum - 1);
        }
      });
    },

    show: function () {
      // show first so that DOM elements have non-zero dimensions.
      this.viewContainer.show();
      this.update();
    },

    hide: function () {
      this.viewContainer.hide();
    },

    update: function () {
      // whenever we reflow, we unbind and rebind the events
      // so that they drag and snap how they should.
      this.destroy();
      this.render();
    },

    destroy: function () {
      // tear down function to un-set everything needed for this view.
      this.unbindEvents();
    },

    bindDragEvent: function (node, options) {
      var self = this;

      node.draggable({
        zIndex: 2700,
        stop: function (event, ui) {
          // `this` === current `node`, as set by jQuery
          self.unrankSelection($(this));
        },
      });

      if (options) {
        for (var i; i < options.length; i++) {
          $(node).draggable('option', options[i], options[options[i]]);
        }
      }
    },

    bindDropEvent: function (node, options) {
      var self = this;
      node.droppable({
        classes: {
          'ui-droppable-hover': 'drophover',
        },
        tolerance: 'pointer', // Mouse pointer overlaps the droppable
        drop: function (event, ui) {
          // `this` === current `node`, as set by jQuery
          self.rankSelection($(this), ui.draggable);
          // This hack is needed as the hover class disappears as soon as the drop event is fired
          self.justDropped = true;
        },
      });

      if (options) {
        for (var i; i < options.length; i++) {
          $(node).droppable('option', options[i], options[options[i]]);
        }
      }

      self.unlock_index[self.active_view] = $(
        '#rankPlacementBlock' + self.active_view + ' .rank.hasRank'
      ).length;
      for (var n = 0; n < node.length; n++) {
        if (n <= self.unlock_index[self.active_view]) {
          $(node[n]).droppable('option', 'disabled', false);
        } else {
          $(node[n]).droppable('option', 'disabled', true);
        }
      }
    },
  });

  var WideRankingWidgetView = RankingWidgetView.extend({
    identifier: 'wideView',
    selector: '#wideView',
    mode: 'Wide',

    render: function () {
      WideRankingWidgetView.__super__.render.call(this);
      var height = $('.rankPlacementBlock').height();
      $('.rankSelectionBlock').css('min-height', height);
      if (this.active_view !== 'Skinny') {
        this.handleAccessibilityMenu();
      }
    },

    renderSelectionBlock: function (selectionContainer) {
      WideRankingWidgetView.__super__._renderSelectionBlock.call(
        this,
        selectionContainer,
        true
      );
    },

    unbindEvents: function (selectionObject, rankSlot) {
      // completely unbind dragables and droppables
      if ($('.rankSelect').droppable('instance'))
        $('.rankSelect').droppable('destroy');
      if ($('.rank').draggable('instance')) $('.rank').draggable('destroy');
    },

    handleAccessibilityMenu: function () {
      var self = this;
      var event = new CustomEvent('load-accessible-menu', {
        detail: {
          actions: {
            selectMenuItem: function (node) {
              var sourceId = $(node).data('source'),
                rankId = $(node).data('rank');
              // Set the selected item into the correct 'ranking' element
              if (rankId) {
                self.rankSelection($('#' + rankId), $('#' + sourceId));
              }
              // Unrank selected item
              else {
                self.unrankSelection($('#' + sourceId));
              }
            },
            moveNode: function () {},
          },
          triggers: {
            button: {
              keydown: true,
              click: false,
            },
            menu: {
              keydown: true,
              click: true,
              mouseover: true,
            },
          },
        },
      });
      window.dispatchEvent(event);
    },
  });

  var SkinnyRankingWidgetView = RankingWidgetView.extend({
    identifier: 'skinnyView',
    selector: '#skinnyView',
    mode: 'Skinny',

    init: function init(element, dataModel, widthController) {
      SkinnyRankingWidgetView.__super__.init.call(
        this,
        element,
        dataModel,
        widthController
      );
      $(this.widthController).on('goWide', $.proxy(this, 'hideModal'));
      return this;
    },

    render: function render() {
      // this.createModal();
      SkinnyRankingWidgetView.__super__.render.call(this);
      this.fillDropdownOptions();
    },

    createModal: function () {
      if (!this._modalCreated) {
        var modal = this.getTemplateHtml('modal', {});
        $('body').append(modal);
        this._modalCreated = true;
      }
    },

    destroy: function destroy() {
      SkinnyRankingWidgetView.__super__.destroy.call(this);
      $('#rankingModalBG').remove();
      $('#rankingModal').remove();
      this._modalCreated = false;
    },

    showModal: function (targetRank) {
      var self = this;

      if (this.dataModel.unranked.length === 0) {
        return;
      }
    },

    hideModal: function () {
      $('#rankingModalBG').hide();
      $('#rankingModal').hide();
    },

    bindEvents: function () {
      var self = this;

      this.viewContainer.find('.rank').on('click.showModal', function () {
        if (!$(this).hasClass('ui-state-disabled')) {
          self.showModal($(this));
        }
      });

      $('#rankingModalBG').on('click.hideModal', this.hideModal);
    },

    rankSelection: function (rankSlot, selectionObject) {
      SkinnyRankingWidgetView.__super__.rankSelection.call(
        this,
        rankSlot,
        selectionObject
      );

      this.hideModal();
    },

    rankDropDownSelection: function (code, slotnum) {
      SkinnyRankingWidgetView.__super__.rankDropdownSelection.call(
        this,
        code,
        slotnum
      );
    },

    unbindEvents: function () {
      // unbind the events
      // delete any objects related to mobile view
      $('.rank').off('click.showModal');
      $('#rankingModalBG').off('click.hideModal');
    },
  });

  Gryphon.widgets = Gryphon.widgets || {};

  Gryphon.widgets.RankingWidget = RankingWidget;

  // TODO: this should be merged into RankingWidget
  var Rank = Gryphon.widgets.Widget.extend(
    {
      render: function render() {
        Rank.__super__.render.call(this);

        var object = this._object;
        var input_id = object.input_id;
        var state =
          input_id in page_state.form_data
            ? $.parseJSON(page_state.form_data[input_id])
            : undefined;

        var data = {
          rankingNumbers: object.slots,
          responseOptions: object.response_options,
          currentState: state,
        };

        var selector = '#rankingWidget';

        var options = {
          containment: selector,
          dk: Boolean(object.dk),
          dk_text: object.dk_text,
          dk_value: object.dk_value,
          selection_help: object.selection_help,
          reverseSlots: Boolean(object.reverse_slots),
          small_selection_placeholder: object.small_selection_placeholder,
          unrank: Boolean(object.unrank),
          min: object.min,
          required: object.required,
          wide_view: object.wide_view,
        };

        this._actualWidget = new RankingWidget(
          selector,
          input_id,
          data,
          options
        );

        // Accessible element for previous answers
        this._object.answer = this._actualWidget.dataModel.slots.filter(
          (slot) => slot.item !== null
        );
        if (this._object.answer && this._object.answer.length > 0) {
          this._object.answer.forEach((answer) => {
            $(`#${this._object.input_id}_note`).append(
              `Rank ${answer.label} is ${answer.item.text}. `
            );
          });
        }
      },

      destroy: function destroy() {
        Rank.__super__.destroy.call(this);
        this._actualWidget.destroy();
      },
    },
    {
      types: ['rank'],
      views: ['rank'],
    }
  );

  Rank.register();
  Gryphon.widgets.Rank = Rank;
})(jQuery);
