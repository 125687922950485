(function (widgets) {
  var baseClass = 'image-highlight',
    baseEvent = 'imgmap.';

  var classes = {
    question: baseClass,
    blockLevel: '-block',
    grid: 'grid-table-mode',
    textarea: 'textarea',
    widget: '-widget',
    markers: '-markers',
    markersDisabled: '-markers-disabled',
    marker: '-marker',
    markerDisabled: '-marker-disabled',
    markerDot: '-marker-dot',
    markerIcon: '-marker-icon',
    markerCaption: '-marker-caption',
    board: '-board',
    shape: '-shape',
    shapeRemove: '-shape-remove',
    iconRemove: 'fa fa-times',
    dragCursor: '-drag-cursor',
    active: '-active',
    selected: '-selected',
    qerror: 'hasError',
    error: '-error',
  };

  var events = {
    shape: {
      startDraw: '',
      drawing: '',
      stopDraw: '',
      activate: '',
      startMove: '',
      moving: '',
      stopMove: '',
      startResize: '',
      resizing: '',
      stopResize: '',
      beforeRemove: '',
      afterRemove: '',
    },
    marker: {
      beforeChange: '',
      afterChange: '',
    },
  };

  var savedMarks = false;
  var imageScale = 0;

  var IS_TOUCH = (function () {
    var check = false;
    /* eslint-disable no-useless-escape*/
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  })();

  var ImageHighlight = widgets.Widget.extend(
    {
      render: function render() {
        ImageHighlight.__super__.render.call(this);
        var settings = this._object;
        $.extend(settings, {
          debug: false,
          minShapeSize: 30,
        });

        $.each(classes, function (key, value) {
          if (value.substring(0, 1) === '-') {
            classes[key] = baseClass + value;
          }
        });

        this.fill_previous_answers(self);

        var self = this;
        var $body = $('body');
        var $question = $('.question-image-highlight');

        this.$el.find('input:checkbox').uniform();

        self.classes = classes;
        self.events = events;
        self.selectors = {};

        $.each(classes, function (key, value) {
          self.selectors[key] = '.' + value;
        });

        var $grid = $question.find(self.selectors.grid);
        var $textarea = $grid.find(self.selectors.textarea);
        var $widget = $question.find(self.selectors.widget);
        var $markers = $widget.find(self.selectors.markers);
        var $marker = $markers.find(self.selectors.marker);
        var $markerDot = $markers.find(self.selectors.markerDot);
        var $markerIcon = $marker.find(self.selectors.markerIcon);
        var $markerCaption = $marker.find(self.selectors.markerCaption);
        var $board = $widget.find(self.selectors.board);

        self.settings = settings;
        self.question = $question;
        self.grid = $grid;
        self.textarea = $textarea;
        self.widget = $widget;
        self.markers = $markers;
        self.marker = $marker;
        self.markerDot = $markerDot;
        self.markerIcon = $markerIcon;
        self.markerCaption = $markerCaption;
        self.board = $board;

        var helpers = (self.helpers = {
          round: function (number) {
            return Math.round(number * 100) / 100;
          },
          getXY: function (e) {
            var $this = $(e.target);
            var offset = $this.offset();

            var x = Math.max.apply(Math.max, [e.pageX - offset.left, 0]);
            if (x) {
              x = Math.min.apply(Math.min, [x, $this.width()]);
            }

            var y = Math.max.apply(Math.max, [e.pageY - offset.top, 0]);
            if (y) {
              y = Math.min.apply(Math.min, [y, $this.height()]);
            }

            return {
              x: x,
              y: y,
            };
          },
          standardizePoints: function (points) {
            var css = points;

            if (typeof css === 'string') {
              css = css.split(' ');

              $.each(css, function (index, value) {
                css[index] = parseFloat(value);
              });
            }

            if ($.isArray(css)) {
              var x, y, w, h;

              if (css[2] < css[0]) {
                x = css[2];
                w = css[0] - css[2] + 1;
              } else {
                x = css[0];
                w = css[2] - css[0] + 1;
              }
              if (css[3] < css[1]) {
                y = css[3];
                h = css[1] - css[3] + 1;
              } else {
                y = css[1];
                h = css[3] - css[1] + 1;
              }

              $.each([x, y, w, h], function (index, value) {
                css[index] = value + 'px';
              });

              css = {
                left: css[0],
                top: css[1],
                width: css[2],
                height: css[3],
              };
            }

            return css;
          },
          createShape: function ($board, $marker, points) {
            $(window).trigger('shape.startDraw');
            var shapeColor =
              $marker.data('marker-color') !== 'rgba(0, 0, 0, 0)' &&
              $marker.data('marker-color') !== ''
                ? $marker.css('border-color')
                : $marker.css('background-color');

            // if there is not defined color
            if (!$marker.data('marker-color')) {
              shapeColor = $('.image-highlight-marker')
                .eq($marker.data('marker-index'))
                .css('border-color');
            }

            var borderColor = shapeColor;
            return helpers
              .updateShape(
                $('<div/>')
                  .addClass(self.classes.shape)
                  .css({
                    border: '1px solid ' + borderColor,
                  })
                  .data({
                    'marker-index': $marker.data('marker-index'),
                    'marker-label': $marker.data('marker-label'),
                  })
                  .append(
                    '<div class="mask" style="background: ' +
                      shapeColor +
                      '" />'
                  ),
                points
              )
              .appendTo($board);
          },
          updateShape: function ($shape, points) {
            return $shape.css(helpers.standardizePoints(points));
          },
          finalizeShape: function (self, $shape, zIndex) {
            var minShapeSize = self.settings.minShapeSize;

            var $thisShape = $shape
              .draggable({
                appendTo: 'parent',
                containment: 'parent',
                stop: function (event, ui) {
                  var markerIndex = $(this).data('marker-index');
                  helpers.fillTextarea(
                    self.textarea.eq(markerIndex),
                    helpers.filterShape(
                      self.board.find(self.selectors.shape),
                      markerIndex
                    )
                  );
                },
                zIndex: Number.MAX_VALUE,
              })
              .resizable({
                containment: 'parent',
                minHeight: self.settings.minShapeSize,
                minWidth: self.settings.minShapeSize,
                stop: function (event, ui) {
                  var markerIndex = $(this).data('marker-index');
                  helpers.fillTextarea(
                    self.textarea.eq(markerIndex),
                    helpers.filterShape(
                      self.board.find(self.selectors.shape),
                      markerIndex
                    )
                  );
                },
              })
              .css({
                zIndex: zIndex,
              })
              .addClass(self.classes.active);

            var shapeOffset = $thisShape.offset();
            var shapeLeft = shapeOffset.left;
            var shapeTop = shapeOffset.top;
            var shapeWidth = $thisShape.width();
            var shapeHeight = $thisShape.height();

            if (shapeHeight && shapeWidth) {
              if (shapeHeight < minShapeSize) {
                shapeHeight = minShapeSize;
              }

              var shapeBottom = shapeTop + shapeHeight;

              if (shapeWidth < minShapeSize) {
                shapeWidth = minShapeSize;
              }

              var shapeRight = shapeLeft + shapeWidth;

              var $thisBoard = self.board;

              var boardOffset = $thisBoard.offset();
              var boardLeft = boardOffset.left;
              var boardTop = boardOffset.top;
              var boardWidth = $thisBoard.width();
              var boardHeight = $thisBoard.height();
              var boardRight = boardLeft + boardWidth;
              var boardBottom = boardTop + boardHeight;

              if (shapeRight > boardRight) {
                shapeLeft -= shapeRight - boardRight;
              }

              if (shapeBottom > boardBottom) {
                shapeTop -= shapeBottom - boardBottom;
              }

              return helpers.addRemoveButton(
                $thisShape
                  .offset({
                    left: shapeLeft,
                    top: shapeTop,
                  })
                  .height(shapeHeight)
                  .width(shapeWidth)
              );
            } else {
              $thisShape.remove();
              return $('');
            }
          },
          removeShape: function ($shape) {
            return $shape.remove();
          },
          addRemoveButton: function ($shape) {
            var color = $shape[0].firstChild.attributes[1].value.replace(
              'background: ',
              ''
            );
            return $shape.append(
              $('<i/>')
                .addClass(self.classes.shapeRemove)
                .attr('tabindex', 0)
                .append(
                  '<svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
                    '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
                    '<g transform="translate(-1056.000000, -304.000000)">' +
                    '<g transform="translate(1056.000000, 304.000000)">' +
                    '<circle cx="12" cy="12" r="12" fill="' +
                    color +
                    '"></circle>' +
                    '<path d="M16.3225256,8.1835419 C16.0858931,7.94690937 15.7036405,7.94690937 15.467008,8.1835419 L12.5,11.1444824 L9.53299204,8.1774744 C9.2963595,7.94084187 8.91410694,7.94084187 8.6774744,8.1774744 C8.44084187,8.41410694 8.44084187,8.7963595 8.6774744,9.03299204 L11.6444824,12 L8.6774744,14.967008 C8.44084187,15.2036405 8.44084187,15.5858931 8.6774744,15.8225256 C8.91410694,16.0591581 9.2963595,16.0591581 9.53299204,15.8225256 L12.5,12.8555176 L15.467008,15.8225256 C15.7036405,16.0591581 16.0858931,16.0591581 16.3225256,15.8225256 C16.5591581,15.5858931 16.5591581,15.2036405 16.3225256,14.967008 L13.3555176,12 L16.3225256,9.03299204 C16.5530906,8.802427 16.5530906,8.41410694 16.3225256,8.1835419 Z" fill="' +
                    $(self.selectors.selected).css('color') +
                    '" fill-rule="nonzero"></path>' +
                    '</g>' +
                    '</g>' +
                    '</g>' +
                    '</svg>'
                )
            );
          },
          filterShape: function ($shape, markerIndex) {
            return $shape.filter(function (index) {
              return $(this).data('marker-index') === markerIndex;
            });
          },
          getHighestZIndex: function ($board) {
            var hZIndex = -1;
            $board.find(self.selectors.shape).each(function (index) {
              var zIndex = $(this).css('z-index');
              if (zIndex > hZIndex && zIndex < Number.MAX_VALUE) {
                hZIndex = zIndex;
              }
            });
            return hZIndex;
          },
          fillTextarea: function ($textarea, $shape) {
            var data = [];
            var $image = self.question.find('[data-src]');
            var imageRealWidth = parseFloat(
              $('.image-highlight-board img').attr('data-width')
            );
            var imageRealHeight = parseFloat(
              $('.image-highlight-board img').attr('data-height')
            );

            $shape.each(function (index) {
              var $this = $(this);
              var offset = $this.position();
              // scaled values based on original/current dimensions
              var left = self.helpers.round(
                offset.left * $image.data().scale.width
              );
              // area selected needs to be inside the image
              if (left < 0) left = 0;
              var top = self.helpers.round(
                offset.top * $image.data().scale.height
              );
              if (top < 0) top = 0;
              var width = self.helpers.round(
                (offset.left + $this.width()) * $image.data().scale.width
              );
              // area selected can't be bigger than actual image
              if (width > imageRealWidth) {
                width = imageRealWidth;
              }
              var height = self.helpers.round(
                (offset.top + $this.height()) * $image.data().scale.height
              );
              if (height > imageRealHeight) {
                height = imageRealHeight;
              }

              var points = [left, top, width, height];

              data.push(points.join(', '));
            });
            return $textarea.val(data.join('\n'));
          },
          toggleMarkerState: function (marker, enable) {
            if (enable) {
              marker
                .removeClass(self.classes.markerDisabled)
                .attr('aria-disabled', false);
            } else {
              marker
                .addClass(self.classes.markerDisabled)
                .attr('aria-disabled', true);
            }
          },
        });

        this.setup_events(self);
        this.setup_markers(self);
        this.preloadImages(self, function () {
          self.setup_board(self);
          self.widget.width(self.board.find('img').width());
          var image = self.question.find('[data-src]');

          // Interval to wait for image loading before
          // setting the scale values
          var imgInt;
          imgInt = setInterval(function () {
            if ($(image).height() > 0 && $(image).width() > 0) {
              clearInterval(imgInt);

              $(image)
                // set scale object with dimension ratios
                // ie. no resizing would be 1:1 (w:h)
                .data('scale', {
                  height: $(image).data().height / $(image).height(),
                  width: $(image).data().width / $(image).width(),
                });

              if (savedMarks) {
                self.setup_grid(self);

                var i = 0;
                $('.textarea').each(function (key, textarea) {
                  var v = textarea.value.split('\n'); // 4 in a line
                  if (!textarea.value.length) return;

                  v.forEach(function (point) {
                    var v = point.split(', ');

                    // position
                    $('.image-highlight-shape')[i].style.left =
                      parseFloat(v[0]) / imageScale + 'px';
                    $('.image-highlight-shape')[i].style.top =
                      parseFloat(v[1]) / imageScale + 'px';

                    // size
                    $('.image-highlight-shape')[i].style.width =
                      parseFloat($('.image-highlight-shape')[i].style.width) /
                        imageScale +
                      'px';
                    $('.image-highlight-shape')[i].style.height =
                      parseFloat($('.image-highlight-shape')[i].style.height) /
                        imageScale +
                      'px';

                    i++;
                  });
                });
              }
            }
          }, 100);
        });

        $(window).on('orientationchange', function () {
          self.render();
        });
      },

      setup_events: function setup_events(self) {
        $.each(self.events, function (key, value) {
          if (typeof value === 'object') {
            $.each(value, function (subkey, subvalue) {
              if (!subvalue) {
                self.events[key][subkey] = subkey.toLowerCase();
              }
            });
          } else {
            if (!value) {
              self.events[key] = key.toLowerCase();
            }
          }
        });

        $(window).on('shape.startDraw', function (evt) {
          var highlights = $('.image-highlight-shape'),
            dk = $('.dk-input input').is(':checked');
          if (dk) {
            $('.dk-input input').prop('checked', false);
            $('.dk-input span').removeClass('uniform-checked');
          }
          highlights.show();
        });

        $('.image-hightlight-widget-marker').on('click', function () {
          $(this)
            .addClass(self.classes.selected)
            .siblings()
            .removeClass(self.classes.selected);
        });

        $('.dk-input input').on('change', function (evt) {
          var highlights = $('.image-highlight-shape'),
            isChecked = evt.currentTarget.checked;
          $(this).blur().trigger('changed');
          return isChecked ? highlights.hide() : highlights.show();
        });
        handle_keyboard_checkbox('.dk-input input');

        self.widget.on({
          contextmenu: function (e) {
            e.preventDefault();
          },
          mousedown: function (e) {
            e.preventDefault();
          },
          selectstart: function (e) {
            e.preventDefault();
          },
        });

        mainNav.nextButton.bind('click keypress', $.proxy(self, '_next'));
      },

      preloadImages: function (self, callback) {
        var $pimage = self.question.find('[data-src]'),
          length = $pimage.length,
          count = 0;

        $pimage.each(function (index) {
          var $image = $(this);
          // create an Image object we can track events on
          var image = new Image();

          image.onload = function () {
            count++;

            if ($image.prop('tagName').toLowerCase() === 'img') {
              $image.prop({
                src: image.src,
              });
              $image
                .attr('data-width', image.width)
                .attr('data-height', image.height)
                .data('width', image.width)
                .data('height', image.height)
                // set max-width image size (for use with mobile devices)
                .css({
                  'max-width': image.width + 'px',
                });
            }

            if (count === length) {
              if (typeof callback === 'function') {
                callback();
              }
            }

            var imageRealWidth = parseFloat(
              $('.image-highlight-board img').attr('data-width')
            );
            var imageWidth = $('.image-highlight-board img').width();
            imageScale = imageRealWidth / imageWidth;
          };

          image.src = $image.data('src');
        });
      },

      setup_markers: function setup_markers(self) {
        var smarker = self.marker.filter(self.selectors.selected).eq(-1);
        if (!smarker.length) {
          smarker = self.marker.eq(0).addClass(self.classes.selected);
        }

        smarker
          .siblings()
          .filter(self.selectors.selected)
          .removeClass(self.classes.selected);
        self.markers.on(
          {
            click: function () {
              if (!$(self.markers).hasClass(self.classes.markersDisabled)) {
                $(this)
                  .addClass(self.classes.selected)
                  .siblings()
                  .removeClass(self.classes.selected);
              }
            },
          },
          self.selectors.marker
        );
        $(self.markers)
          .on('enable', function () {
            $(self.markers)
              .removeClass(self.classes.markersDisabled)
              .attr('aria-disabled', false);
          })
          .on('disable', function () {
            $(self.markers)
              .addClass(self.classes.markersDisabled)
              .attr('aria-disabled', true);
          });
      },

      fill_previous_answers: function fill_previous_answers(self) {
        Object.entries(window.page_state.form_data).forEach(([key, value]) => {
          if ($('textarea[name="' + key + '"]').val()) savedMarks = true;
        });
      },

      setup_grid: function setup_grid(self) {
        self.grid
          .on(
            {
              keyup: function (e) {
                var $this = $(this),
                  index = self.textarea.index($this),
                  points = $this.val().split('\n'),
                  hZIndex = self.helpers.getHighestZIndex(self.board);
                self.helpers
                  .filterShape(self.board.find(self.selectors.shape), index)
                  .remove();
                for (var i = 0; i < points.length; i++) {
                  hZIndex++;
                  self.helpers
                    .finalizeShape(
                      self,
                      self.helpers.createShape(
                        self.board,
                        self.marker.eq(index),
                        points[i]
                      ),
                      hZIndex
                    )
                    .removeClass(self.classes.active);
                }
              },
            },
            self.selectors.textarea
          )
          .find(self.selectors.textarea)
          .filter(function (index) {
            return (this.value || '').length > 0;
          })
          .trigger('keyup');
      },

      setup_board: function setup_board(self) {
        var zIndex = 0;
        var $shape = $('');
        var x1, y1, x2, y2;
        var c = 0;

        function count(num) {
          c = c + num < 0 ? 0 : c + num;
          if (c >= 25) {
            // Disable after we hit 25
            self.board.draggable('option', 'disabled', true);
            $(self.markers).trigger('disable');
          } else {
            self.board.draggable('option', 'disabled', false);
            $(self.markers).trigger('enable');
          }
          $.each($(self.marker), function (idx, marker) {
            var v = $('.answers textarea')[idx].value;
            if (
              v !== '' &&
              v.split('\n').length >= parseInt($(marker).data('marker-max'))
            ) {
              self.helpers.toggleMarkerState($(marker), false);
            } else {
              self.helpers.toggleMarkerState($(marker), true);
            }
          });
        }

        self.board
          .draggable({
            appendTo: self.board,
            containment: self.board,
            delay: IS_TOUCH ? 300 : 0,
            drag: function (e, ui) {
              var xy = self.helpers.getXY(e);
              x2 = xy.x;
              y2 = xy.y;
              self.helpers.updateShape($shape, [x1, y1, x2, y2]);
            },
            distance: self.settings.minShapeSize,
            helper: function () {
              return $('<div/>').addClass(self.classes.dragCursor);
            },
            scroll: true,
            stack: self.selectors.shape,
            start: function (e, ui) {
              if (
                self.marker
                  .filter(self.selectors.selected)
                  .hasClass(self.classes.markerDisabled)
              ) {
                return false;
              }
              var xy = self.helpers.getXY(e);
              x1 = x2 = xy.x;
              y1 = y2 = xy.y;
              var $this = $(this);
              $this
                .find(self.selectors.shape + self.selectors.active)
                .removeClass(self.classes.active);
              $shape = self.helpers
                .createShape(
                  $this,
                  self.marker.filter(self.selectors.selected),
                  [x1, y1, x2, y2]
                )
                .addClass(self.classes.active);
            },
            stop: function (e, ui) {
              zIndex++;
              var markerIndex = self.helpers
                .finalizeShape(self, $shape, zIndex)
                .data('marker-index');
              $shape = $('');
              self.helpers.fillTextarea(
                self.textarea.eq(markerIndex),
                self.helpers.filterShape(
                  self.board.find(self.selectors.shape),
                  markerIndex
                )
              );
              count(1);
            },
          })
          .on({
            mousedown: function (e) {
              var $this = $(e.target);
              var $shape = $('');
              var $them = $('');

              if ($this.hasClass(self.classes.board)) {
                $them = $this.find(self.selectors.shape);
              }

              if ($this.hasClass(self.classes.shape)) {
                $shape = $this;
              }

              if ($this.hasClass(self.classes.shapeRemove)) {
                $shape = $this.closest(self.selectors.shape);
              }

              if ($shape.length) {
                $them = $shape
                  .css({
                    zIndex: self.helpers.getHighestZIndex($(this)) + 1,
                  })
                  .addClass(self.classes.active)
                  .siblings();
              }

              if ($them.length) {
                $them.removeClass(self.classes.active);
              }
            },
          })
          .on({
            click: function (e) {
              global.disable_dk_check();
              var $this = $(e.target);
              if (
                $this.hasClass(self.classes.shapeRemove) ||
                $(e.target).is('circle')
              ) {
                var $shape = $this.closest(self.selectors.shape);
                var markerIndex = $shape.data('marker-index');
                $shape.remove();
                self.helpers.fillTextarea(
                  self.textarea.eq(markerIndex),
                  self.helpers.filterShape(
                    $(this).find(self.selectors.shape),
                    markerIndex
                  )
                );
                count(-1);
              }
            },
          })
          .on({
            keypress: function (e) {
              if (e.which !== 32 && e.which !== 13) {
                return;
              }
              var $this = $(e.target);
              if ($this.hasClass(self.classes.shapeRemove)) {
                var $shape = $this.closest(self.selectors.shape);
                var markerIndex = $shape.data('marker-index');
                $shape.remove();
                self.helpers.fillTextarea(
                  self.textarea.eq(markerIndex),
                  self.helpers.filterShape(
                    $(this).find(self.selectors.shape),
                    markerIndex
                  )
                );
                count(-1);
              }
            },
          });
      },

      _next: function _next(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var self = this;
        var err_msg = '';
        self._object.error_message = '';
        $.each($(self.marker), function (idx, marker) {
          var v = $('.answers textarea')[idx].value,
            min = parseInt($(marker).data('marker-min'));
          if ((v === '' && min > 0) || v.split('\n').length < min) {
            var text = $(marker).find('.image-highlight-marker-caption').text();
            err_msg =
              'Please provide a minimum of ' +
              parseInt($(marker).data('marker-min')) +
              ' response(s) for "' +
              text +
              '" option.<br>';
            self._object.error_message += err_msg;
          }
        });
        if (self._object.error_message !== '') {
          if ($('.alert-error').length > 0) {
            $('.alert-error').html(self._object.error_message);
          } else {
            $(
              '<div class="alert alert-error">' +
                self._object.error_message +
                '</div>'
            ).prependTo($('.image-highlight-widget'));
          }
          return;
        } else {
          click_next({ key: ev.key, type: ev.type });
        }
      },
    },
    {
      types: ['image-highlight'],
      views: ['image-highlight'],
    }
  );

  ImageHighlight.register();
  widgets.ImageHighlight = ImageHighlight;
})((Gryphon.widgets = Gryphon.widgets || {}));
